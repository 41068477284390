import React from "react";

import theme from "../../../global/theme";

import ProductCard from "./ProductCard";
import { SimpleGrid } from "@chakra-ui/react";

const AvailableList = ({ data }) => {
  return (
    <SimpleGrid
      bg={theme.light}
      boxShadow={theme.shadow}
      columns={{ base: 2, md: 2, lg: 3 }}
      // minChildWidth="170px"
      spacing={{ base: 2, lg: 6 }}
      p={4}
      borderRadius={18}
    >
      {data.map((el) => (
        <ProductCard key={el._id} el={el} />
      ))}
    </SimpleGrid>
  );
};

export default AvailableList;
