import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi"; // Replacing GiIcons with BiIcons
import {
  Flex,
  Text,
  Alert,
  AlertIcon,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import MarktersAccountsTable from "./MarketersAccountsTable";
import Filter from "./actions/Filter";

import { getAccounts } from "../../../store/accounts/accountsSlice";

import { MarktersAccountsWrapper } from "./MarketersAccountsStyle";
import OpenModal from "../users/actions/OpenModal";
import theme from "../../global/theme";
import UpdateAccount from "./actions/UpdateAccount";
import formatNumber from "../../../utilities/formatNumber";

const MarktersAccounts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sort, setSort] = useState("availableToWithdraw");
  const accounts = useSelector((state) => state.accounts);
  const [showUpdateAccount, setShowUpdateAccount] = useState(false);
  const [account, setAccount] = useState(null);
  const [showOpenUser, setShowOpenUser] = useState(false);

  const [page, setPage] = useState(1);
  const defaultFilter = { role: "marketer" };
  const [filter, setFilter] = useState(JSON.stringify(defaultFilter));

  useEffect(() => {
    dispatch(getAccounts({ page, query: "", filter, sort }));
  }, [dispatch, page, filter, sort]);

  // Function to format numbers with commas

  return (
    <MarktersAccountsWrapper>
      <Breadcrumbs
        currentPage={t("pages.accounts.accounts")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {accounts.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {accounts.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} mb={8} spacing={6}>
        <Flex
          boxShadow={theme.shadow}
          bg="#FFDDC1" // Light Coral
          padding={6}
          borderRadius={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box color={theme.dark}>
            <MdIcons.MdPendingActions size={40} /> {/* Pending Actions Icon */}
          </Box>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.dark}
            >
              {formatNumber(accounts.data?.pendingBalance)}
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.dark}
            >
              أرصدة معلقة
            </Text>
          </Box>
        </Flex>

        <Flex
          boxShadow={theme.shadow}
          bg="#B9FBC0" // Light Green
          padding={6}
          borderRadius={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box color={theme.dark}>
            <AiIcons.AiOutlineClockCircle size={40} /> {/* Clock Icon */}
          </Box>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.dark}
            >
              {formatNumber(accounts.data?.preparingBalance)}
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.dark}
            >
              أرصدة جار التحضير
            </Text>
          </Box>
        </Flex>

        <Flex
          boxShadow={theme.shadow}
          bg="#C3C4E0" // Light Blue
          padding={6}
          borderRadius={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box color={theme.dark}>
            <FaIcons.FaBoxOpen size={40} /> {/* Open Box Icon */}
          </Box>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.dark}
            >
              {formatNumber(accounts.data?.shippedBalance)}
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.dark}
            >
              أرصدة تم الشحن
            </Text>
          </Box>
        </Flex>

        <Flex
          boxShadow={theme.shadow}
          bg="#FAD02E" // Light Yellow
          padding={6}
          borderRadius={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box color={theme.dark}>
            <FaIcons.FaTruckLoading size={40} /> {/* Truck Loading Icon */}
          </Box>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.dark}
            >
              {formatNumber(accounts.data?.availableBalance)}
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.dark}
            >
              أرصدة تم التوصيل
            </Text>
          </Box>
        </Flex>

        <Flex
          boxShadow={theme.shadow}
          bg="#E2E8F0" // Light Gray
          padding={6}
          borderRadius={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box color={theme.dark}>
            <AiIcons.AiOutlineWallet size={40} /> {/* Wallet Icon */}
          </Box>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.dark}
            >
              {formatNumber(accounts.data?.availableToWithdrawBalance)}
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.dark}
            >
              أرصدة قابلة للسحب
            </Text>
          </Box>
        </Flex>

        <Flex
          boxShadow={theme.shadow}
          bg="#BEE3F8" // Light Sky Blue
          padding={6}
          borderRadius={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box color={theme.dark}>
            <MdIcons.MdAttachMoney size={40} /> {/* Attach Money Icon */}
          </Box>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.dark}
            >
              {formatNumber(accounts.data?.totalCommission)}
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.dark}
            >
              إجمالي العمولات
            </Text>
          </Box>
        </Flex>

        <Flex
          boxShadow={theme.shadow}
          bg="#A0AEC0" // Light Gray Blue
          padding={6}
          borderRadius={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box color={theme.dark}>
            <BiIcons.BiBarChartSquare size={40} /> {/* Bar Chart Icon */}
          </Box>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.dark}
            >
              {formatNumber(accounts.data?.totalDoneRequests)}
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.dark}
            >
              أرصدة تم سحبها
            </Text>
          </Box>
        </Flex>
      </SimpleGrid>

      <Filter
        handleFilter={(values) =>
          setFilter(JSON.stringify({ ...values, ...defaultFilter }))
        }
        handleClearFilter={() => setFilter(JSON.stringify(defaultFilter))}
        disablesBtns={accounts.isLoading}
      />

      {accounts.data?.itemsCount > 0 ? (
        <MarktersAccountsTable
          data={accounts.data.data}
          page={page}
          setSort={setSort}
          sort={sort}
          setShowUpdateAccount={setShowUpdateAccount}
          setAccount={setAccount}
          handleOpen={(el) => setShowOpenUser(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.accounts.no_accounts")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={accounts.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showUpdateAccount && (
        <UpdateAccount
          data={account}
          onClose={() => {
            setShowUpdateAccount(false);
            setAccount(null);
            dispatch(getAccounts({ page, query: "", filter, sort }));
          }}
        />
      )}

      {showOpenUser && (
        <OpenModal
          data={showOpenUser}
          isOpen={showOpenUser}
          onClose={() => setShowOpenUser(false)}
        />
      )}
    </MarktersAccountsWrapper>
  );
};

export default MarktersAccounts;
