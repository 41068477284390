import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Flex
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import { deleteRule } from '../../../../store/rules/rulesSlice';
import theme from '../../../global/theme';


const DeleteModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rules = useSelector(state => state.rules);

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={20} padding={4} bg={theme.dark}>
          <form onSubmit={(e) => {
            e.preventDefault();
            dispatch(deleteRule(data)).unwrap().then(_ => {
              onClose();
            }).catch(_ => {
              onClose();
            });
          }}>
            <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
              {t('general.delete')}
            </ModalHeader>
            <ModalBody>
              <Flex alignItems="center" justifyContent="center">
                <Text color={theme.error} marginEnd={4}>
                  <FiIcons.FiAlertTriangle size={24} />
                </Text>
                <Text as="h3" textTransform="capitalize" fontWeight="semibold" color="white">
                  {t('general.delete_item')}
                </Text>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Flex justifyContent="flex-end">
                <Button
                  type="submit"
                  rightIcon={<FiIcons.FiSave />}
                  color="white" bg="green" paddingInline={4}
                  paddingBlock={2} height="auto" textTransform="capitalize"
                  isLoading={rules.isLoading}
                  _hover={{ background: 'green' }}
                >
                  {t('general.save')}
                </Button>
                <Button
                  type="button"
                  onClick={onClose}
                  rightIcon={<FiIcons.FiMinimize2 />}
                  color="white" bg="red.600" paddingInline={4}
                  paddingBlock={2} height="auto" textTransform="capitalize"
                  marginInlineStart={4}
                  isLoading={rules.isLoading}
                  _hover={{ background: 'red.600' }}
                >
                  {t('general.close')}
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteModal