import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDuplicateOrders } from "../../../store/orders/ordersSlice";
import {
  Box,
  Flex,
  Text,
  Badge,
  Stack,
  Icon,
  Divider,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { FaPhone, FaUser, FaDollarSign, FaWarehouse } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DuplicateOrders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { duplicateOrders } = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(getDuplicateOrders({}));
  }, [dispatch]);
  const checkDuplicateOrders = (order) => {
    let query = "";
    order.duplicateOrders.forEach((item) => {
      query += `${item.serial_number} `;
    });
    navigate(`/orders?page=1&size=20&query=${query}&status=`);
  };

  return (
    <SimpleGrid columns={[2, 3]} spacing={6} p={6}>
      {duplicateOrders.map((order) => (
        <Box
          key={order._id}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={6}
          bg="gray.50"
          shadow="md"
          transition="all 0.3s"
          _hover={{ shadow: "lg" }}
          cursor="pointer"
          onClick={() => checkDuplicateOrders(order)}
        >
          <Flex align="center" mb={4}>
            <Icon as={FaUser} mr={3} color="blue.600" />
            <Text fontSize="lg" fontWeight="bold" color="gray.700">
              رقم العميل: {order.client_phone1}
            </Text>
          </Flex>

          <Divider my={3} />

          <Text fontSize="xl" fontWeight="bold" mb={4} color="blue.700">
            الطلبات المتشابهة :
          </Text>

          {order.duplicateOrders.map((item) => (
            <VStack
              key={item._id}
              spacing={2}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              bg="white"
              shadow="sm"
              _hover={{ shadow: "md" }}
            >
              <Flex align="center">
                <Icon as={FaWarehouse} mr={2} color="gray.600" />
                <Text color="gray.600">كود الطلب: {item.serial_number}</Text>
              </Flex>

              <Flex align="center">
                <Badge
                  colorScheme={item.status === "pending" ? "yellow" : "green"}
                  variant="solid"
                  fontSize="0.8em"
                >
                  {t(`pages.orders.${item.status}`)}
                </Badge>
              </Flex>
            </VStack>
          ))}
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default DuplicateOrders;
