import { useSelector } from "react-redux";
import { Box, Divider } from "@chakra-ui/react";

import theme from "../../../global/theme";

const AccountTab = () => {
  const auth = useSelector(state => state.auth);
  return (
    <Box bg={theme.light} boxShadow={theme.shadow} p={6} borderRadius={6}>
      <Box>الاسم</Box>
      <Box>{auth.user.name}</Box>
      <Divider marginBlock={4} />
      <Box>الكود</Box>
      <Box>{auth.user.code}</Box>
      <Divider marginBlock={4} />
      <Box>البريد الالكتروني</Box>
      <Box>{auth.user.email}</Box>
      <Divider marginBlock={4} />
      <Box>رقم الهاتف</Box>
      <Box>{auth.user.phone}</Box>
      <Divider marginBlock={4} />
      <Box>نوع الحساب</Box>
      <Box>{auth.user.role}</Box>
      <Divider marginBlock={4} />
    </Box>
  )
}

export default AccountTab