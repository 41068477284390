import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Initial state
const initialState = {
  isLoading: false,
  data: {},

  errors: [],
};

// Thunks
export const getAlerts = createAsyncThunk(
  "products/getAlerts",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_alerts?page=${page}&size=${size}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createAlert = createAsyncThunk(
  "products/createAlert",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/send_alert", args, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateAlert = createAsyncThunk(
  "products/updateAlert",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.patch(
        `/api/update_alert/${args._id}`,
        args.values,
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const readMyAlerts = createAsyncThunk(
  "products/readMyAlerts",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.patch(
        `/api/read_all_alert`,
        {},
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const alertSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle getProducts
      .addCase(getAlerts.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getAlerts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getAlerts.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      })
      // Handle read_all_alert
      .addCase(readMyAlerts.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(readMyAlerts.fulfilled, (state, action) => {
        state.isLoading = false;

        state.errors = [];
      })
      .addCase(readMyAlerts.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle updateAccessProduct
      .addCase(updateAlert.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.data.data) {
          state.data.data = state.data.data.map((el) =>
            el._id === action.payload.data._id ? action.payload.data : el
          );
        }
        state.errors = [];
      })
      .addCase(updateAlert.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })
      // Handle updateAccessProduct
      .addCase(createAlert.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createAlert.fulfilled, (state, action) => {
        state.isLoading = false;

        state.errors = [];
      })
      .addCase(createAlert.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default alertSlice.reducer;
