import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./auth/authSlice";
import sidebarSlice from "./sidebar/sidebarSlice";

import cartSlice from "./cart/cartSlice";

import notificationsSlice from "./notifications/notificationsSlice";
// dashboard slices
import statisticsSlice from "./dashboard/statisticsSlice";

import rulesSlice from "./rules/rulesSlice";
import categoriesSlice from "./categories/categoriesSlice";
import shippingCompaniesSlice from "./shippingCompanies/shippingCompaniesSlice";
import priceListSlice from "./priceList/priceListSlice";
import reportsSlice from "./reports/reportsSlice";

import usersSlice from "./users/usersSlice";
import moderatorsSlice from "./moderators/moderatorsSlice";
import warehousesSlice from "./warehouses/warehousesSlice";
import productsSlice from "./products/productsSlice";
import stockSlice from "./stock/stockSlice";
import suggestedSlice from "./suggestions/suggestedSlice";
import ordersSlice from "./orders/ordersSlice";
import easyOrderSlice from "./easyOrder/easyOrderSlice";
import historySlice from "./history/historySlice";
import accountsSlice from "./accounts/accountsSlice";
import requestsSlice from "./requests/requestsSlice";
import alertSlice from "./alerts/alertSlice";
import blacklistSlice from "./blacklist/blacklistSlice";

import supportsSlice from "./supports/supportsSlice";
import productsOrderSlice from "./products/productsOrderSlice";

export default configureStore({
  reducer: {
    cart: cartSlice,
    sidebar: sidebarSlice,
    notifications: notificationsSlice,
    statistics: statisticsSlice,
    reports: reportsSlice,
    rules: rulesSlice,
    alerts: alertSlice,
    categories: categoriesSlice,
    shippingCompanies: shippingCompaniesSlice,
    priceList: priceListSlice,
    auth: authSlice,
    users: usersSlice,
    moderators: moderatorsSlice,
    warehouses: warehousesSlice,
    products: productsSlice,
    stock: stockSlice,
    productsOrder: productsOrderSlice,
    easyOrder: easyOrderSlice,
    suggested: suggestedSlice,
    orders: ordersSlice,
    history: historySlice,
    accounts: accountsSlice,
    requests: requestsSlice,
    supports: supportsSlice,
    blacklist: blacklistSlice,
  },
});
