import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Text,
  Alert,
  AlertIcon,
  Select,
  Stack,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import { updateRequest } from "../../../../store/requests/requestsSlice";

import theme from "../../../global/theme";

const UpdateModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const requests = useSelector((state) => state.requests);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });

  watch(["status"]);
  console.log(data);
  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values) => {
            dispatch(
              updateRequest({
                id: values._id,
                values: {
                  _id: values._id,
                  status: values.status,
                  reason: values.reason,
                },
              })
            )
              .unwrap()
              .then((_) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            {t("general.update")}
          </ModalHeader>
          <ModalBody>
            {requests.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {requests.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <Stack spacing={6}>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.requests.status")}
                </FormLabel>
                <Select
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  {...register("status", {
                    required: `${t("validation.required")}`,
                  })}
                >
                  <option style={{ color: "#222222" }} value="approved">
                    موافقة
                  </option>
                  <option style={{ color: "#222222" }} value="rejected">
                    تم الرفض
                  </option>
                </Select>
                {errors.status?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.status.message}
                  </Text>
                )}
              </FormControl>

              {getValues("status") === "rejected" && (
                <FormControl>
                  <FormLabel
                    fontWeight="bold"
                    textTransform="capitalize"
                    color="white"
                  >
                    ملاحظة
                  </FormLabel>
                  <Input
                    bg={theme.bg}
                    color={theme.dark}
                    border="none"
                    borderRadius={4}
                    {...register("reason", {
                      required: `${t("validation.required")}`,
                    })}
                  />
                  {errors.reason?.message && (
                    <Text color="red.600" marginTop={2}>
                      {errors.reason.message}
                    </Text>
                  )}
                </FormControl>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={requests.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={requests.isLoading}
                _hover={{ background: "red.600" }}
                onClick={onClose}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateModal;
