import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

// Thunks
export const getSupports = createAsyncThunk(
  "supports/getSupports",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_supports?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createSupport = createAsyncThunk(
  "supports/createSupport",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_support", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateSupport = createAsyncThunk(
  "supports/updateSupport",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_support/${args._id}`,
        args,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const deleteSupport = createAsyncThunk(
  "supports/deleteSupport",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(`/api/delete_support/${args._id}`, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

// Slice
const supportsSlice = createSlice({
  name: "supports",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get supports
      .addCase(getSupports.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getSupports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getSupports.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors || [];
      })

      // Create support
      .addCase(createSupport.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createSupport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount = (state.data.itemsCount || 0) + 1;
        if (state.data?.data?.length < 10) {
          state.data.data = [...(state.data.data || []), action.payload.data];
        }
        state.errors = [];
      })
      .addCase(createSupport.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Update support
      .addCase(updateSupport.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateSupport.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.data.data?.findIndex(
          (el) => el._id === action.payload.data._id
        );
        if (index !== -1) {
          state.data.data[index] = action.payload.data;
        }
        state.errors = [];
      })
      .addCase(updateSupport.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Delete support
      .addCase(deleteSupport.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteSupport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.data = state.data.data?.filter(
          (el) => el._id !== action.payload.data._id
        );
        state.errors = [];
      })
      .addCase(deleteSupport.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      });
  },
});

export default supportsSlice.reducer;
