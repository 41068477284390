import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal, ModalOverlay,
  ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Button, FormControl,
  FormLabel, Input,
  SimpleGrid,
  Flex, Text,
  Alert, AlertIcon, Checkbox
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import { updateModerator } from '../../../../store/moderators/moderatorsSlice';

import theme from '../../../global/theme';

const Update = ({ data, onClose }) => {
  const { t } = useTranslation();
  const moderators = useSelector(state => state.moderators);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: data
  });

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit(values => {
          dispatch(updateModerator(values)).unwrap().then(_ => {
            onClose();
          });
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('general.update')}
          </ModalHeader>
          <ModalBody>

            {moderators.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
              <AlertIcon />
              {moderators.errors?.map((error, index) => (
                <Text key={index}>{error?.msg}</Text>
              ))}
            </Alert>}

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.moderators.name')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.moderators.name')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("name", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.name?.message &&
                  <Text color="red.600" marginTop={2}>{errors.name.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.moderators.email')}
                </FormLabel>
                <Input type="email" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.moderators.email')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("email", {
                    required: `${t('validation.required')}`,
                  })}
                />
                {errors.email?.message &&
                  <Text color="red.600" marginTop={2}>{errors.email.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.moderators.phone')}
                </FormLabel>
                <Input type="tel" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.moderators.phone')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("phone", {
                    required: `${t('validation.required')}`,
                  })}
                />
                {errors.phone?.message &&
                  <Text color="red.600" marginTop={2}>{errors.phone.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.moderators.status')}
                </FormLabel>

                <Checkbox color={theme.light}
                  {...register("is_active")}>{t('pages.moderators.is_active')}</Checkbox>
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={moderators.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={moderators.isLoading}
                _hover={{ background: 'red.600' }}
                onClick={onClose}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default Update