import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  ModalFooter,
  Flex,
  Button,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import theme from "../../../global/theme";

const RequestStatusModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={20} padding={8} bg={theme.dark}>
        <Table color="white">
          <Thead>
            <Tr>
              <Td>الحالة</Td>
              <Td>التاريخ</Td>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>تم الإنشاء</Td>
              <Td>{new Date(data.created_at).toLocaleString()}</Td>
            </Tr>
            {data.actions.map((el) => (
              <Tr key={el._id}>
                <Td>
                  {el.status}{" "}
                  {auth.user.role === "admin" && `(${el.admin?.name})`}
                </Td>
                <Td>{new Date(el.created_at).toLocaleString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <ModalFooter p={0}>
          <Flex justifyContent="flex-end" mt={8}>
            <Button
              type="button"
              onClick={onClose}
              rightIcon={<FiIcons.FiMinimize2 />}
              color="white"
              bg="red.600"
              paddingInline={4}
              paddingBlock={2}
              height="auto"
              textTransform="capitalize"
              _hover={{ background: "red.600" }}
            >
              {t("general.close")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RequestStatusModal;
