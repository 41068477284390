import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { permissions } from '../../../../utilities/permissions';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Flex,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import { createRule } from '../../../../store/rules/rulesSlice';
import theme from '../../../global/theme';


const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const rules = useSelector(state => state.rules);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      permissions: []
    }
  });

  watch('permissions');

  return (
    <Modal isOpen={true} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent borderRadius={4} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit((values) => {
          dispatch(createRule(values)).unwrap().then(_ => {
            onClose();
          });
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('pages.rules.create_rule')}
          </ModalHeader>
          <ModalBody>

            {rules.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
              <AlertIcon />
              {rules.errors?.map((error, index) => (
                <Text key={index}>{error?.msg}</Text>
              ))}
            </Alert>}

            <FormControl>
              <FormLabel color={theme.light} fontWeight="bold" textTransform="capitalize">
                {t('pages.rules.name')}
              </FormLabel>
              <Input type="text" bg={theme.light} border="none" borderRadius={4}
                placeholder={t('pages.rules.name')} _placeholder={{ textTransform: 'capitalize' }}
                {...register("name", {
                  required: `${t('validation.required')}`
                })}
              />
              {errors.name?.message &&
                <Text color="red.600" marginTop={2}>{errors.name.message}</Text>}
            </FormControl>

            <Flex mt={8}>
              <Button
                type="button"
                bg={theme.success} color={theme.light}
                _hover={{ bg: theme.success }}
                onClick={() => {
                  const allPermissions = [];
                  for (let i = 0; i < permissions.length; i++) {
                    const singlePermission = permissions[i];
                    for (let j = 0; j < singlePermission.page_access.length; j++) {
                      const access = singlePermission.page_access[j];
                      allPermissions.push(access + "_" + singlePermission.page_name);
                    }
                  }
                  setValue('permissions', allPermissions);
                }}
              >
                {t('pages.rules.check_all')}
              </Button>

              <Button
                type="button" bg="red.600" _hover={{ bg: "red.600" }} color="white" ms={3}
                onClick={() => setValue('permissions', [])}
              >
                {t('pages.rules.remove_all')}
              </Button>
            </Flex>

            {permissions.map(el => (
              <FormControl mt={4} pb={2} key={el.page_name} borderBottom="1px solid #ccc">
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {el.page_name}
                </FormLabel>
                <SimpleGrid columns={{ sm: 1, md: 2 }}>
                  {el.page_access.map(access => (
                    <Flex key={access}>
                      <input
                        type="checkbox" value={access + "_" + el.page_name}
                        {...register('permissions')} id={access + "_" + el.page_name}
                      />
                      <label
                        style={{ color: "white", marginInlineStart: "8px", textTransform: 'capitalize' }}
                        htmlFor={access + "_" + el.page_name}
                      >
                        {access} {el.page_name}
                      </label>
                    </Flex>
                  ))}
                </SimpleGrid>
              </FormControl>
            ))}

          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={rules.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={rules.isLoading}
                _hover={{ background: 'red.600' }}
                onClick={onClose}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateModal