import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Box,
  Textarea,
  SimpleGrid,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import Search from "../../../shared/search/Search";
import { updateOrderCallAction } from "../../../../store/orders/ordersSlice";
import { getProducts } from "../../../../store/products/productsSlice";
import theme from "../../../global/theme";

const UpsellModal = ({ data, onClose, currentOrder }) => {
  const { t } = useTranslation();
  const products = useSelector((state) => state.products);
  const toast = useToast({ duration: 2000, position: "top", status: "error" });
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: { items: [{}] },
  });

  const { fields: items, remove: removeItem } = useFieldArray({
    name: "items",
    control,
  });

  watch(["items"]);

  const [productsPage, setProductsPage] = useState(1);
  const [productsQuery, setProductsQuery] = useState("");
  const productsDefaultFilter = { is_active: true, is_upsell: true };
  const [productsFilter] = useState(JSON.stringify(productsDefaultFilter));
  useEffect(() => {
    dispatch(
      getProducts({
        page: productsPage,
        query: productsQuery,
        filter: productsFilter,
      })
    );
  }, [dispatch, productsPage, productsQuery, productsFilter]);
  const generateUpsellMessage = (
    serialNumber,
    clientPhone,
    items,
    note = ""
  ) => {
    const phone = "2" + clientPhone;
    const step1 = `لأنك عميل مميز وطلبت أوردر من عندنا
تقدر تزود منتجات على طلبك بنص الثمن والشحن مجاني توصلك مع الأوردر الأصلي.
       `;
    const step2 = `${items.map(
      (item, idx) =>
        `${item.product?.name} - ${item.product.upsell_message}   
       
         ${items?.length === idx + 1 ? "" : "//"} `
    )}`;

    const step3 = ``;
    const step4 = `✅ لتأكيد الأوردر الأصلي فقط، دوس (1)

✅ لتأكيد الأوردر الأصلي ➕ العرض الإضافي بنصف الثمن، دوس (2)

 🔃 لتعديل أو إلغاء الأوردر، دوس (3)`;

    const message = `${step1}\n\n${step2}\n\n${step3}\n\n${step4}\n`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
      phone
    )}&text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };
  return (
    <Modal isOpen={true} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit(async (values) => {
            const items = values.items;
            const note = values.note;
            // global validation
            if (items.length === 0)
              return toast({
                description: "من فضلك اختر على الاقل منتج واحد!",
              });
            console.log(items);
            dispatch(
              updateOrderCallAction({
                _id: currentOrder._id,
                action_name: data?.action_name,
              })
            )
              .unwrap()
              .then((_) => {
                generateUpsellMessage(
                  data.serialNumber,
                  data.phone,
                  items,
                  note
                );
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            إقتراح منتجات أخري
          </ModalHeader>
          <ModalBody>
            {items.map((item, index) => (
              <SimpleGrid
                key={item.id}
                columns={{ sm: 1, md: 4, lg: 5 }}
                border={`1px solid ${theme.border}`}
                alignItems="center"
                paddingBlock={2}
                mb={2}
                borderRadius={4}
              >
                <Box marginInline={5}>
                  <Box color={theme.light} marginBlock={2}>
                    المنتج
                  </Box>
                  <Search
                    page={productsPage}
                    itemsCount={products.data?.itemsCount}
                    data={products.data?.data}
                    prop="name"
                    currentElement={getValues(`items.${index}.product`)}
                    handleChangePage={(page) => {
                      setProductsPage(page);
                    }}
                    handleChangeQuery={(query) => {
                      setProductsQuery(query);
                    }}
                    handleSelected={(item) => {
                      // check if the product selected
                      const products = getValues("items");
                      for (let i = 0; i < products.length; i++) {
                        if (
                          products[i].product?.warehouse?._id &&
                          products[i].product?.warehouse?._id !==
                            item.warehouse?._id
                        ) {
                          return alert("choose another warehouse");
                        }
                      }
                      setValue(`items.${index}.product`, item);
                    }}
                  />
                </Box>

                <Box marginInline={5} mt={2}>
                  {index === 0 ? (
                    <Button bg={theme.success} color={theme.light} size="xs">
                      إضافة قطعة اخرى للطلب
                    </Button>
                  ) : (
                    <Button
                      bg="red"
                      color="white"
                      size="xs"
                      onClick={() => removeItem(index)}
                    >
                      حذف قطعة
                    </Button>
                  )}
                </Box>
              </SimpleGrid>
            ))}

            {/* <FormControl mt={6}>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                {t("pages.orders.note")}
              </FormLabel>
              <Textarea
                bg={theme.bg}
                color={theme.dark}
                border="none"
                placeholder={t("pages.orders.note")}
                _placeholder={{ textTransform: "capitalize" }}
                {...register("note", {
                  maxLength: {
                    value: 150,
                    message: `${t("validation.max_length")} 150`,
                  },
                })}
              />
              {errors.note?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.note.message}
                </Text>
              )}
            </FormControl> */}
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpsellModal;
