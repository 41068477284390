import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Initial state
const initialState = {
  isLoading: false,
  data: [],
  product: null,
  errors: [],
};

// Thunks
export const getStockRules = createAsyncThunk(
  "products/getStockRules",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/get_stocks_rules`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateStockRule = createAsyncThunk(
  "products/updateProduct",
  async (args, thunkApi) => {
    let payload = args
      .filter((item) => item.product?._id)
      .map((item) => {
        return { ...item, product: item.product._id };
      });
    try {
      const { data } = await axios.patch(
        `/api/update_stocks_rules`,
        { payload },
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const stockSlice = createSlice({
  name: "stock",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle getProducts
      .addCase(getStockRules.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getStockRules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getStockRules.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle updateProduct
      .addCase(updateStockRule.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateStockRule.fulfilled, (state, action) => {
        state.isLoading = false;

        state.errors = [];
      })
      .addCase(updateStockRule.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default stockSlice.reducer;
