import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import {
  Box, Button, Flex,
  FormControl,
  FormLabel,
  Heading,
  Input, Stack,
  Link as ChakraLink,
  Text,
  Image,
  Divider,
  useToast,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import theme from '../../global/theme';

import { changePassword } from '../../../store/auth/authSlice';

import { AuthWarpper } from './AuthStyle';

import brandImage from '../../../assets/images/logo.png';

import authBg from '../../../assets/images/auth-bg.jpg';

const ChangePassword = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: 'top', duration: 5000, status: 'error' });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [searchParams] = useSearchParams();


  const [isPasswordShown, setIsPasswordShown] = useState(false);

  useEffect(() => {
    if (!searchParams.get("token")) {
      navigate('/login');
    }
  }, [navigate, searchParams]);

  return (
    <AuthWarpper>
      <Flex background={`url(${authBg}) no-repeat`} backgroundSize="cover" minH="100vh" justifyContent="center" alignItems="center">
        <Box bg={theme.dark} p={8} className="auth-box" borderRadius={10}>

          <Box textAlign="center">
            <Image m="auto" h="80px" src={brandImage} mb={4} />

            <Heading
              color={theme.light} fontSize={28} textTransform="capitalize" mb={4}
            >
              {t('pages.auth.welcome_1')}
            </Heading>

            <Heading
              color={theme.text} fontSize={14} textTransform="capitalize"
            >
              {t('pages.auth.change_password')}
            </Heading>
          </Box>

          <Divider borderColor={theme.border} marginBlock={6} />

          <form onSubmit={handleSubmit(values => {
            dispatch(changePassword({
              token: searchParams.get("token"),
              values
            })).unwrap().then(_ => {
              navigate('/login');
            }).catch(error => {
              toast({
                description: error.errors && error.errors[0]?.msg,
              });
            })
          })}>
            <Stack spacing={4} mt={4}>
              <FormControl>
                <FormLabel
                  color={theme.light} textTransform="capitalize"
                >
                  {t('pages.auth.password')}
                </FormLabel>
                <InputGroup>
                  <Input
                    type={isPasswordShown ? 'text' : 'password'} placeholder={t('pages.auth.password')}
                    border="none" color={theme.dark} bg={theme.bg}
                    _placeholder={{ color: theme.text }} paddingInline={4}
                    {...register('password', {
                      required: `${t('validation.required')}`,
                      minLength: {
                        value: 8,
                        message: `${t('validation.min_length')} 8`
                      }
                    })}
                  />
                  <InputRightElement>
                    <Button p={0} bg="none" color={theme.dark} _hover={{ bg: "none" }}
                      type="button" onClick={() => setIsPasswordShown(!isPasswordShown)}
                    >
                      {isPasswordShown ? <FiIcons.FiEyeOff /> : <FiIcons.FiEye />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password?.message && (
                  <Text color={theme.error}>{errors.password?.message}</Text>
                )}
              </FormControl>

              <Button
                type="submit" textTransform="capitalize"
                bg={theme.blue} color={theme.light}
                _hover={{ bg: theme.blue }}
                isLoading={auth.isLoading}
              >
                {t('pages.auth.change_password')}
              </Button>

              <ChakraLink
                as={Link} to="/login"
                textTransform="capitalize"
              >
                <Flex>
                  <Text color={theme.light}>{t('pages.auth.have_an_account')}</Text>
                  <Text ms={2} color={theme.blue}>{t('pages.auth.login')}</Text>
                </Flex>
              </ChakraLink>

            </Stack>
          </form>
        </Box>
      </Flex>
    </AuthWarpper>
  )
}

export default ChangePassword