import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal, ModalOverlay,
  ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Button, FormControl,
  FormLabel, Input,
  Flex, Text, Alert, AlertIcon,
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import { createWarehouse } from '../../../../store/warehouses/warehousesSlice';

import theme from '../../../global/theme';

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const warehouses = useSelector(state => state.warehouses);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit(values => {
          dispatch(createWarehouse(values)).unwrap().then(_ => {
            onClose();
          });
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('pages.warehouses.create_warehouse')}
          </ModalHeader>
          <ModalBody>

            {warehouses.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
              <AlertIcon />
              {warehouses.errors?.map((error, index) => (
                <Text key={index}>{error?.msg}</Text>
              ))}
            </Alert>}

            <FormControl mb={6}>
              <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                {t('pages.warehouses.name')}
              </FormLabel>
              <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                placeholder={t('pages.warehouses.name')} _placeholder={{ textTransform: 'capitalize' }}
                {...register("name", {
                  required: `${t('validation.required')}`
                })}
              />
              {errors.name?.message &&
                <Text color="red.600" marginTop={2}>{errors.name.message}</Text>}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={warehouses.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={warehouses.isLoading}
                _hover={{ background: 'red.600' }}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateModal