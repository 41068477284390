import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Button, Text, Alert, AlertIcon } from '@chakra-ui/react';

import Pagination from '../../shared/pagination/Pagination';
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs';
import SupportsTable from './SupportsTable';
import CreateModal from './actions/CreateModal';
import UpdateModal from './actions/UpdateModal';
import DeleteModal from './actions/DeleteModal';

import { getSupports } from '../../../store/supports/supportsSlice';

import { SupportsWrapper } from './SupportsStyle';
import theme from '../../global/theme';


const Supports = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const supports = useSelector(state => state.supports);
  const auth = useSelector(state => state.auth);

  const [page, setPage] = useState(1);
  const [query] = useState("");
  const defaultFilter = auth.user.role === "admin" ? {} : { for: auth.user.role };
  const [filter] = useState(JSON.stringify(defaultFilter));

  const [showCreateSupport, setShowCreateSupport] = useState(false);
  const [showUpdateSupport, setShowUpdateSupport] = useState(false);
  const [showDeleteSupport, setShowDeleteSupport] = useState(false);

  useEffect(() => {
    dispatch(getSupports({ page, query, filter }));
  }, [dispatch, page, query, filter]);


  return (
    <SupportsWrapper>
      <Breadcrumbs currentPage={t('pages.supports.supports')} pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]} />

      {supports.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
        <AlertIcon />
        {supports.errors?.map((error, index) => (
          <Text key={index}>{error?.msg}</Text>
        ))}
      </Alert>}

      {auth.user?.role === "admin" && (
        <Flex marginBottom={8}>
          <Button bg="red.600" textTransform="uppercase"
            fontWeight="bold" fontSize={18} marginInlineEnd={4} color="white" _hover={{ bg: 'red.600' }}
            onClick={() => setShowCreateSupport(true)}
          >
            {t('general.create')}
          </Button>
        </Flex>
      )}

      {supports.data?.itemsCount > 0 ? (
        <SupportsTable
          data={supports.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateSupport(el)}
          handleDelete={(el) => setShowDeleteSupport(el)}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} boxShadow={theme.shadow} height={200} alignItems="center"
          justifyContent="center" borderRadius={4}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.supports.no_supports')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={supports.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateSupport && <CreateModal onClose={() => setShowCreateSupport(false)} />}
      {showUpdateSupport && <UpdateModal data={showUpdateSupport} onClose={() => setShowUpdateSupport(false)} />}
      {showDeleteSupport && <DeleteModal data={showDeleteSupport} onClose={() => setShowDeleteSupport(false)} />}
    </SupportsWrapper>
  )
}

export default Supports