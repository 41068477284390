import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
  Spinner,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import ProductsTable from "./ProductsTable";
import MinProductsTable from "./MinProductsTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import UpdateAccessModal from "./actions/UpdateAccessModal";
import Filter from "./actions/Filter";

import { getMinProducts } from "../../../store/products/productsSlice";

import { ProductsWrapper } from "./ProductsStyle";

import theme from "../../global/theme";
import InputSearch from "../../shared/inputSearch/InputSearch";
import UpdateStockModal from "./actions/UpdateStockModal";
import { useSearchParams } from "react-router-dom";

const MinProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const auth = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get("page") || 1;
  const query = searchParams.get("query") || "";

  // State for filter and switches
  const [filter, setFilter] = useState({
    is_active: true,
    is_archived: false,
  });

  useEffect(() => {
    dispatch(getMinProducts({ page, query, filter: JSON.stringify(filter) }));
  }, [dispatch, page, query, filter]);

  // Handle switch toggle

  return (
    <ProductsWrapper>
      <Breadcrumbs
        currentPage={"منتجات ناقصة ستوك"}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {products.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {products.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      {products.isLoading ? (
        <Flex padding={6} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : products.min?.itemsCount > 0 ? (
        <MinProductsTable data={products.min.data} page={page} />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.products.no_products")}
          </Text>
        </Flex>
      )}

      {products.min?.itemsCount > 0 && !products.isLoading && (
        <Flex justifyContent="flex-end">
          <Pagination
            page={page}
            itemsCount={products.min?.itemsCount ?? 0}
            onChange={(page) => {
              searchParams.set("page", page);
              setSearchParams(searchParams);
            }}
          />
        </Flex>
      )}
    </ProductsWrapper>
  );
};

export default MinProducts;
