import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import * as FiIcons from 'react-icons/fi';

import Table from '../../shared/table/Table';
import theme from '../../global/theme';
import { governorates } from '../../../utilities/places';
import { useSelector } from "react-redux";

const PriceListTable = ({ data, page, handleUpdate }) => {
  const { t } = useTranslation();
  const auth = useSelector(state => state.auth);

  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>{t('pages.price_list.governorate')}</th>
          <th>{t('pages.price_list.price')}</th>
          {auth.user.role === "admin" && (
            <th>{t('general.action')}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{((page - 1) * 10) + (index + 1)}</td>
            <td>{governorates.find(gov => +gov.id === el.governorate)?.governorate_name_ar}</td>
            <td>{el.price}</td>
            {auth.user.role === "admin" && (
              <td>
                <Button
                  bg="transparent" color={theme.success} size="xs" _hover={{ bg: 'transparent' }}
                  onClick={() => handleUpdate(el)}
                >
                  <FiIcons.FiEdit size={20} />
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default PriceListTable