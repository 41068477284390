import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  SimpleGrid,
  Flex,
  Text,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightElement,
  Divider,
  useToast,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import theme from "../../../global/theme";
import { createSuggested } from "../../../../store/suggestions/suggestedSlice";

const SuggestProuct = ({ onClose }) => {
  const { t } = useTranslation();
  const products = useSelector((state) => state.suggested);
  const toast = useToast({ position: "top", status: "success" });
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values, event) => {
            const formData = new FormData(event.target);

            dispatch(createSuggested(formData))
              .unwrap()
              .then((res) => {
                toast({
                  description: "تم إرسال الإقتراح بنجاح",
                });
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            إقتراح منتج
          </ModalHeader>
          <ModalBody>
            {products.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {products.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  اسم المنتج
                </FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder="اسم المنتج"
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("name", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.name?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.name.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  سعر التكلفة المقترح
                </FormLabel>
                <Input
                  type="number"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder="سعر التكلفة المقترح"
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("sale_price")}
                />
                {errors.sale_price?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.sale_price.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.image")}
                </FormLabel>
                <InputGroup>
                  <Input
                    type="file"
                    bg={theme.bg}
                    color={theme.dark}
                    border="none"
                    height="auto"
                    padding=".4rem 1rem"
                    _placeholder={{ textTransform: "capitalize" }}
                    {...register("image")}
                  />
                  <InputRightElement
                    color={theme.light}
                    children={<FiIcons.FiImage />}
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  رابط المنتج (إختياري)
                </FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={"رابط خاص بالمنتج"}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("media_url")}
                />
                {errors.media_url?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.media_url.message}
                  </Text>
                )}
              </FormControl>
            </SimpleGrid>

            <FormControl mt={6}>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                {t("pages.products.description")}
              </FormLabel>
              <Textarea
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                // placeholder={t("pages.products.description")}
                placeholder={
                  " يرجي توفير وصف للمنتج مثل إذا كنت تحتاجه (منتج خاص أو منتج للجميع) ومعدل السحب الأسبوعي تقريباً"
                }
                _placeholder={{ textTransform: "capitalize" }}
                {...register("description")}
              />
              {errors.description?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.description.message}
                </Text>
              )}
            </FormControl>

            <Divider marginBlock={6} />
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={products.isLoading}
                _hover={{ background: "green" }}
              >
                إرسال الإقتراح
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={products.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default SuggestProuct;
