import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button,
  Alert,
  AlertIcon,
  useToast,
  Text,
  Box
} from '@chakra-ui/react';

import * as FaIcons from 'react-icons/fa';

import { updateUserPassword } from '../../../../store/users/usersSlice';

import { ProfileWrapper } from '../ProfileStyle';


const UpdateTab = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: 'top', duration: 2000, status: "success" });
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const users = useSelector(state => state.users);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <ProfileWrapper>
      <Box borderRadius={12} p={6} bg="white">
        {users.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {users.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>}

        <form onSubmit={handleSubmit(values => {
          dispatch(updateUserPassword({ id: auth.user._id, values })).unwrap().then(_ => {
            toast({ description: 'تم تحديث كلمة المرور بنجاح' });
          });
        })}>
          <FormControl mb={8}>
            <FormLabel fontWeight="bold" textTransform="capitalize">كلمة المرور</FormLabel>
            <Input type="password" bg="#F1F2F8" border="none"
              placeholder="كلمة المرور" _placeholder={{ textTransform: 'capitalize', fontFamily: 'Segoe UI' }}
              {...register("password", {
                required: `${t('validation.required')}`,
                minLength: {
                  value: 8,
                  message: `${t('validation.min_length')} 8`
                }
              })}
            />
            {errors.password?.message &&
              <Text color="red.600" marginTop={2}>{errors.password.message}</Text>}
          </FormControl>

          <FormControl mb={8}>
            <FormLabel fontWeight="bold" textTransform="capitalize">كلمة المرور الجديده</FormLabel>
            <Input type="password" bg="#F1F2F8" border="none"
              placeholder="كلمة المرور الجديدة" _placeholder={{ textTransform: 'capitalize', fontFamily: 'Segoe UI' }}
              {...register("new_password", {
                required: `${t('validation.required')}`,
                minLength: {
                  value: 8,
                  message: `${t('validation.min_length')} 8`
                }
              })}
            />
            {errors.new_password?.message &&
              <Text color="red.600" marginTop={2}>{errors.new_password.message}</Text>}
          </FormControl>

          <FormControl mb={8}>
            <FormLabel fontWeight="bold" textTransform="capitalize">تاكيد كلمة المرور الجديدة</FormLabel>
            <Input type="password" bg="#F1F2F8" border="none"
              placeholder="تاكيد كلمة المرور الجديدة" _placeholder={{ textTransform: 'capitalize', fontFamily: 'Segoe UI' }}
              {...register("confirm_new_password", {
                required: `${t('validation.required')}`,
                minLength: {
                  value: 8,
                  message: `${t('validation.min_length')} 8`
                }
              })}
            />
            {errors.confirm_new_password?.message &&
              <Text color="red.600" marginTop={2}>{errors.confirm_new_password.message}</Text>}
          </FormControl>

          <Flex justifyContent="flex-end" marginTop={8}>
            <Button
              type="submit"
              rightIcon={<FaIcons.FaSdCard />}
              color="white" bg="green.600" paddingInline={4}
              paddingBlock={2} height="auto" textTransform="capitalize"
              isLoading={users.isLoading}
              _hover={{ background: 'green.600' }}
            >
              {t('general.save')}
            </Button>
            <Button
              type="reset"
              rightIcon={<FaIcons.FaBan />}
              color="white" bg="red.600" paddingInline={4}
              paddingBlock={2} height="auto" textTransform="capitalize"
              marginInlineStart={4}
              isLoading={users.isLoading}
              _hover={{ background: 'red.600' }}
            >
              {t('general.reset')}
            </Button>
          </Flex>
        </form>
      </Box>
    </ProfileWrapper>
  )
}

export default UpdateTab