import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { FormControl, FormLabel, Text, Button, Input, SimpleGrid, Flex } from '@chakra-ui/react';

import Search from '../../../shared/search/Search';

import { getUsers } from '../../../../store/users/usersSlice';

import theme from "../../../global/theme";


const Filter = ({ handleFilter, handleClearFilter, disablesBtns }) => {
  const { t } = useTranslation();
  const { register, watch, getValues, setValue, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);

  watch(['marketer']);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter] = useState(JSON.stringify({ role: "marketer" }))

  useEffect(() => {
    dispatch(getUsers({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <form onSubmit={handleSubmit(values => {
      let finalValues = {};
      for (let prop in values) {
        if (values[prop]) {
          if (prop === "marketer") {
            finalValues["marketer"] = values.marketer?._id
          } else {
            finalValues[prop] = values[prop];
          }
        }
      }
      handleFilter(finalValues);
    })}>

      <SimpleGrid columns={{ base: 2, md: 2, lg: 3 }} spacing={6} bg="#eee" p={2} mb={8}>
        <FormControl>

          <FormLabel fontWeight="bold" textTransform="capitalize" color="black">
            {t('general.from')}
          </FormLabel>
          <Input type="date" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
            placeholder={t('general.from')} _placeholder={{ textTransform: 'capitalize' }}
            {...register("from")}
          />
          {errors.from?.message &&
            <Text color="red.600" marginTop={2}>{errors.from.message}</Text>}
        </FormControl>

        <FormControl>
          <FormLabel fontWeight="bold" textTransform="capitalize" color="black">
            {t('general.to')}
          </FormLabel>
          <Input type="date" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
            placeholder={t('general.to')} _placeholder={{ textTransform: 'capitalize' }}
            {...register("to")}
          />
          {errors.to?.message &&
            <Text color="red.600" marginTop={2}>{errors.to.message}</Text>}
        </FormControl>

        <FormControl>
          <FormLabel fontWeight="bold" textTransform="capitalize" color="black">
            {t('pages.accounts.marketer')}
          </FormLabel>
          <Search
            page={page}
            itemsCount={users.data?.itemsCount}
            data={users.data?.data}
            prop="name"
            currentElement={getValues("marketer")}
            handleChangePage={(page) => { setPage(page) }}
            handleChangeQuery={(query) => { setQuery(query) }}
            handleSelected={(item) => { setValue("marketer", item) }}
          />
          {errors.marketer?.message &&
            <Text color="red.600" marginTop={2}>{errors.marketer.message}</Text>}
        </FormControl>

        <Flex alignItems="flex-end">
          <Button type="submit" isLoading={disablesBtns} mt={2} me={2} width="100%" colorScheme="blue">
            فلتر
          </Button>
          <Button type="button" isLoading={disablesBtns} mt={2} width="100%" colorScheme="red"
            onClick={handleClearFilter}
          >
            استعادة
          </Button>
        </Flex>
      </SimpleGrid>

    </form>
  )
}

export default Filter