import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Link, Box } from '@chakra-ui/react';
import * as FiIcons from 'react-icons/fi';
import * as FaIcons from 'react-icons/fa';

import Table from '../../shared/table/Table';
import { useSelector } from "react-redux";

const SupportsTable = ({ data, page, handleUpdate, handleDelete }) => {
  const auth = useSelector(state => state.auth);
  const { t } = useTranslation();

  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          {auth.user?.role === "admin" && (
            <th>{t('general.action')}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>
              <Link
                href={`https://wa.me/2${el.phone}`}
                bg="green.600" color="white" display="flex" justifyContent="center"
                paddingInline={4} paddingBlock={2} borderRadius="5px" alignItems="center"
              >
                <FaIcons.FaWhatsapp size={18} />
                <Box marginInlineStart={2} fontSize="22px" textDecor="underline">
                  {el.description}
                </Box>
              </Link>
            </td>
            {auth.user?.role === "admin" && (
              <td>
                <Button
                  bg="transparent" color="green" size="xs"
                  onClick={() => handleUpdate(el)}
                >
                  <FiIcons.FiEdit size={20} />
                </Button>

                <Button
                  bg="transparent" color="red.600" size="xs"
                  onClick={() => handleDelete(el)}
                >
                  <FiIcons.FiDelete size={20} />
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default SupportsTable