import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Flex, Button, Text, SimpleGrid, Alert, AlertIcon } from '@chakra-ui/react';

import InputSearch from "../../shared/inputSearch/InputSearch";
import Pagination from '../../shared/pagination/Pagination';
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs';
import WarehousesTable from './WarehousesTable';
import CreateModal from './actions/CreateModal';
import UpdateModal from './actions/UpdateModal';
import DeleteModal from './actions/DeleteModal';
import Filter from './actions/Filter';

import { getWarehouses } from '../../../store/warehouses/warehousesSlice';

import { WarehousesWrapper } from './WarehousesStyle';

import theme from '../../global/theme';

const Warehouses = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const warehouses = useSelector(state => state.warehouses);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const [showCreateWarehouse, setShowCreateWarehouse] = useState(false);
  const [showUpdateWarehouse, setShowUpdateWarehouse] = useState(false);
  const [showDeleteWarehouse, setShowDeleteWarehouse] = useState(false);

  useEffect(() => {
    dispatch(getWarehouses({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <WarehousesWrapper>
      <Breadcrumbs currentPage={t('pages.warehouses.warehouses')} pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]} />

      {warehouses.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
        <AlertIcon />
        {warehouses.errors?.map((error, index) => (
          <Text key={index}>{error?.msg}</Text>
        ))}
      </Alert>}

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button bg="red.600" textTransform="uppercase"
            fontWeight="bold" fontSize={18} marginInlineEnd={4} color="white" _hover={{ bg: 'red.600' }}
            onClick={() => setShowCreateWarehouse(true)}
          >
            {t('general.create')}
          </Button>
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={warehouses.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) => setFilter(values)}
            handleClearFilter={() => setFilter(JSON.stringify({}))}
            disablesBtns={warehouses.isLoading}
          />
        </Flex>
      </SimpleGrid>

      {warehouses.data?.itemsCount > 0 ? (
        <WarehousesTable
          data={warehouses.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateWarehouse(el)}
          handleDelete={(el) => setShowDeleteWarehouse(el)}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} boxShadow={theme.shadow} height={200} alignItems="center"
          justifyContent="center" borderRadius={16}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.warehouses.no_warehouses')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={warehouses.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateWarehouse && <CreateModal onClose={() => setShowCreateWarehouse(false)} />}
      {showUpdateWarehouse && <UpdateModal data={showUpdateWarehouse} onClose={() => setShowUpdateWarehouse(false)} />}
      {showDeleteWarehouse && <DeleteModal data={showDeleteWarehouse} onClose={() => setShowDeleteWarehouse(false)} />}
    </WarehousesWrapper>
  )
}

export default Warehouses