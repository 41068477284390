import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import {
  Box, Button, Flex,
  FormControl,
  FormLabel,
  Heading,
  Input, Stack,
  Link as ChakraLink,
  Text,
  Image,
  Divider,
  useToast,
} from '@chakra-ui/react';

import theme from '../../global/theme';

import { resetPassword } from '../../../store/auth/authSlice';

import { AuthWarpper } from './AuthStyle';

import brandImage from '../../../assets/images/logo.png';

import authBg from '../../../assets/images/auth-bg.jpg';

const ResetPassword = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: 'top', duration: 2000, status: 'error' });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <AuthWarpper>
      <Flex background={`url(${authBg}) no-repeat`} backgroundSize="cover" minH="100vh" justifyContent="center" alignItems="center">
        <Box bg={theme.dark} p={8} className="auth-box" borderRadius={10}>

          <Box textAlign="center">
            <Image m="auto" h="80px" src={brandImage} mb={4} />

            <Heading
              color={theme.light} fontSize={28} textTransform="capitalize" mb={4}
            >
              {t('pages.auth.reset_password')}
            </Heading>

            <Heading
              color={theme.text} fontSize={14} textTransform="capitalize"
            >
              {t('pages.auth.welcome_4')}
            </Heading>
          </Box>

          <Divider borderColor={theme.border} marginBlock={6} />

          <form onSubmit={handleSubmit(values => {
            dispatch(resetPassword(values)).unwrap().then(_ => {
              navigate('/login');
            }).catch(error => {
              toast({
                description: error.errors && error.errors[0]?.msg,
              });
            })
          })}>
            <Stack spacing={4}>

              <FormControl>
                <FormLabel
                  color={theme.light} textTransform="capitalize"
                >
                  {t('pages.auth.email')}
                </FormLabel>
                <Input
                  type="text" placeholder={t('pages.auth.email')}
                  border="none" color={theme.dark} bg={theme.bg}
                  _placeholder={{ color: theme.text }} paddingInline={4}
                  {...register('email', {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.email?.message && (
                  <Text color={theme.error}>{errors.email?.message}</Text>
                )}
              </FormControl>

              <Button
                type="submit" textTransform="capitalize"
                bg={theme.blue} color={theme.light}
                _hover={{ bg: theme.blue }}
                isLoading={auth.isLoading}
              >
                {t('pages.auth.reset')}
              </Button>

              <ChakraLink
                as={Link} to="/login"
                textTransform="capitalize"
              >
                <Flex>
                  <Text color={theme.light}>{t('pages.auth.have_an_account')}</Text>
                  <Text ms={2} color={theme.blue}>{t('pages.auth.login')}</Text>
                </Flex>
              </ChakraLink>

            </Stack>
          </form>
        </Box>
      </Flex>
    </AuthWarpper>
  )
}

export default ResetPassword