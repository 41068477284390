import React from "react";
import { useTranslation } from "react-i18next";
import {
  Tag,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Text,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const MostInactiveProductsTable = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Box
      bg={tableBg}
      shadow="md"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      mt={5}
    >
      <Table variant="simple" size="md">
        <Thead>
          <Tr>
            <Th>{t("pages.products.name")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((product) => (
            <Tr
              key={product.name}
              cursor="pointer"
              onClick={() => navigate(`/available_products/${product._id}`)}
            >
              {/* Product Name */}
              <Td>
                <VStack align="start">
                  <Text fontWeight="semibold" color={textColor}>
                    {product.name}
                  </Text>
                </VStack>
              </Td>

              {/* Properties */}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default MostInactiveProductsTable;
