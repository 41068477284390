import { createSlice } from "@reduxjs/toolkit";

// Initial state of the cart
let initialState = {
  items: [], // Array to hold cart items
};

// Create the cart slice
const cartSlice = createSlice({
  name: "cart", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    // Reducer to add an item to the cart
    addToCart: (state, { payload }) => {
      state.items.push(payload);
    },
    // Reducer to remove an item from the cart by propertyId
    removeFromCart: (state, { payload }) => {
      state.items = state.items.filter(
        (el) => el.property?._id !== payload.propertyId
      );
    },
    // Reducer to empty the cart
    emptyCart: (state) => {
      state.items = [];
    },
  },
});

// Export the actions generated by createSlice
export const { addToCart, removeFromCart, emptyCart } = cartSlice.actions;

// Export the reducer to be used in the store
export default cartSlice.reducer;
