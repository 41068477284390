import React, { useState } from "react";
import {
  Box,
  Flex,
  Icon,
  List,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import {
  FaClipboardCheck,
  FaFileAlt,
  FaExclamationCircle,
} from "react-icons/fa";

const getCategoryIcon = (category) => {
  switch (category) {
    case "ordersActions":
      return FaClipboardCheck;
    case "requestsActions":
      return FaExclamationCircle;
    case "ordersNotes":
      return FaFileAlt;
    default:
      return FaFileAlt;
  }
};

const ActivitiesList = ({ filter, activities }) => {
  const filteredActivities =
    filter === "all"
      ? activities
      : activities.filter((activity) => activity.category === filter);

  return (
    <List spacing={4}>
      {filteredActivities.map((activity) => (
        <ListItem
          key={activity._id}
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          _hover={{ bg: "gray.50" }}
          transition="background-color 0.2s"
        >
          <Flex align="center">
            <Icon
              as={getCategoryIcon(activity.category)}
              boxSize={8}
              mr={4}
              color="blue.600"
            />
            <Box>
              <Text fontWeight="semibold" fontSize="lg">
                {activity.description}
              </Text>
              <Text color="gray.600" fontSize="sm">
                {new Date(activity.created_at).toLocaleString()}
              </Text>
            </Box>
          </Flex>
        </ListItem>
      ))}
    </List>
  );
};

const ActivitiesTabs = ({ activities }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const tabCategories = [
    { label: "الكل", value: "all" },
    { label: "إجراءات الطلبات", value: "ordersActions" },
    { label: "طلبات السحب", value: "requestsActions" },
    { label: "ملاحظات الطلبات", value: "ordersNotes" },
  ];

  return (
    <Tabs
      variant="solid-rounded"
      onChange={(index) => setTabIndex(index)}
      colorScheme="blue"
      size="lg"
    >
      <TabList>
        {tabCategories.map((tab, index) => (
          <Tab
            key={index}
            fontWeight="bold"
            _selected={{ color: "white", bg: "blue.500" }}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {tabCategories.map((tab, index) => (
          <TabPanel key={index} p={4}>
            <ActivitiesList activities={activities} filter={tab.value} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default ActivitiesTabs;
