import React from "react";

import { Table as ChakraTable, TableContainer } from "@chakra-ui/react";

import { useLocation } from "react-router-dom";
import { TableWrapper } from "./TableStyle";

const Table = (props) => {
  const { pathname } = useLocation();
  return (
    <TableWrapper
      headBgColor={props.headBgColor}
      headColor={props.headColor}
      headBorder={props.headBorder}
      headBorderRadius={props.headBorderRadius}
      headTextTransform={props.headTextTransform}
      pathname={pathname}
    >
      <TableContainer>
        <ChakraTable {...props}>{props.children}</ChakraTable>
      </TableContainer>
    </TableWrapper>
  );
};

export default Table;
