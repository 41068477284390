import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Text, Alert, AlertIcon, Spinner } from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";

import MostInactiveProductsTable from "./MostInactiveProductsTable";

import {
  getMinProducts,
  getMostInactiveProducts,
} from "../../../store/products/productsSlice";

import { ProductsWrapper } from "./ProductsStyle";

import theme from "../../global/theme";

import { useSearchParams } from "react-router-dom";

const MostInactiveProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get("page") || 1;
  const query = searchParams.get("query") || "";

  // State for filter and switches
  const [filter, setFilter] = useState({
    is_active: true,
    is_archived: false,
  });

  useEffect(() => {
    dispatch(
      getMostInactiveProducts({ page, query, filter: JSON.stringify(filter) })
    );
  }, [dispatch, page, query, filter]);

  // Handle switch toggle

  return (
    <ProductsWrapper>
      <Breadcrumbs
        currentPage={"منتجات  الأقل نشاطاً"}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {products.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {products.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      {products.isLoading ? (
        <Flex padding={6} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : products.low?.itemsCount > 0 ? (
        <MostInactiveProductsTable data={products.low.data} page={page} />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.products.no_products")}
          </Text>
        </Flex>
      )}

      {products.low?.itemsCount > 0 && !products.isLoading && (
        <Flex justifyContent="flex-end">
          <Pagination
            page={page}
            itemsCount={products.low?.itemsCount ?? 0}
            onChange={(page) => {
              searchParams.set("page", page);
              setSearchParams(searchParams);
            }}
          />
        </Flex>
      )}
    </ProductsWrapper>
  );
};

export default MostInactiveProducts;
