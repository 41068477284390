import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Link as ChakraLink,
  Text,
  Image,
  Divider,
  useToast,
  InputGroup,
  InputRightElement,
  Select,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import theme from "../../global/theme";

import { register as authRegister } from "../../../store/auth/authSlice";

import { AuthWarpper } from "./AuthStyle";

import brandImage from "../../../assets/images/logo.png";

import authBg from "../../../assets/images/auth-bg.jpg";

const Register = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 5000, status: "error" });
  const toast2 = useToast({
    position: "top",
    duration: 10000,
    status: "success",
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <AuthWarpper>
      <Flex
        background={`url(${authBg}) no-repeat`}
        backgroundSize="cover"
        minH="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          bg={theme.dark}
          p={8}
          className="auth-box register"
          borderRadius={10}
        >
          <Box textAlign="center">
            <Image m="auto" h="80px" src={brandImage} mb={4} />

            <Heading
              color={theme.light}
              fontSize={28}
              textTransform="capitalize"
              mb={4}
            >
              مرحبا بك مجددا
            </Heading>

            <Heading
              color={theme.text}
              fontSize={14}
              textTransform="capitalize"
            >
              إذا لم يكن لديك حساب، قم بإنشاء حساب جديد
            </Heading>
          </Box>

          <Divider borderColor={theme.border} marginBlock={6} />

          <form
            onSubmit={handleSubmit((values) => {
              dispatch(authRegister(values))
                .unwrap()
                .then((_) => {
                  navigate("/login");
                  toast2({
                    description:
                      "01090371071 .تم التسجيل بنجاح, برجاء التواصل مع الدعم للتنشيط",
                  });
                })
                .catch((error) => {
                  toast({ description: error.errors && error.errors[0]?.msg });
                });
            })}
          >
            <Stack spacing={4} mt={4}>
              <FormControl>
                <FormLabel color={theme.light} textTransform="capitalize">
                  الاسم
                </FormLabel>
                <Input
                  type="text"
                  placeholder="الاسم"
                  border="none"
                  color={theme.dark}
                  bg={theme.bg}
                  _placeholder={{ color: theme.text }}
                  paddingInline={4}
                  {...register("name", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.name?.message && (
                  <Text color={theme.error}>{errors.name?.message}</Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel color={theme.light} textTransform="capitalize">
                  البريد الالكتروني
                </FormLabel>
                <Input
                  type="email"
                  placeholder="البريد الالكتروني"
                  border="none"
                  color={theme.dark}
                  bg={theme.bg}
                  _placeholder={{ color: theme.text }}
                  paddingInline={4}
                  {...register("email", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.email?.message && (
                  <Text color={theme.error}>{errors.email?.message}</Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel color={theme.light} textTransform="capitalize">
                  الهاتف
                </FormLabel>
                <Input
                  type="tel"
                  placeholder="الهاتف"
                  border="none"
                  color={theme.dark}
                  bg={theme.bg}
                  _placeholder={{ color: theme.text }}
                  paddingInline={4}
                  {...register("phone", {
                    required: `${t("validation.required")}`,
                    minLength: {
                      value: 11,
                      message: `${t("validation.min_length")} 11`,
                    },
                  })}
                />
                {errors.phone?.message && (
                  <Text color={theme.error}>{errors.phone?.message}</Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel color={theme.light} textTransform="capitalize">
                  كلمة المرور
                </FormLabel>
                <InputGroup>
                  <Input
                    type={isPasswordShown ? "text" : "password"}
                    placeholder="كلمة المرور"
                    border="none"
                    color={theme.dark}
                    bg={theme.bg}
                    _placeholder={{ color: theme.text }}
                    paddingInline={4}
                    {...register("password", {
                      required: `${t("validation.required")}`,
                      minLength: {
                        value: 8,
                        message: `${t("validation.min_length")} 8`,
                      },
                    })}
                  />
                  <InputRightElement>
                    <Button
                      p={0}
                      bg="none"
                      color={theme.dark}
                      _hover={{ bg: "none" }}
                      type="button"
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                    >
                      {isPasswordShown ? (
                        <FiIcons.FiEyeOff />
                      ) : (
                        <FiIcons.FiEye />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password?.message && (
                  <Text color={theme.error}>{errors.password?.message}</Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel color={theme.light} textTransform="capitalize">
                  نوع الحساب
                </FormLabel>
                <Select
                  border="none"
                  color={theme.dark}
                  bg={theme.bg}
                  {...register("role", {
                    required: `${t("validation.required")}`,
                  })}
                >
                  <option style={{ color: theme.dark }} value="marketer">
                    مسوق
                  </option>
                  <option style={{ color: theme.dark }} value="merchant">
                    تاجر
                  </option>
                </Select>
                {errors.role?.message && (
                  <Text color={theme.error}>{errors.role?.message}</Text>
                )}
              </FormControl>

              <ChakraLink
                as={Link}
                to="/reset_password"
                textTransform="capitalize"
              >
                <Flex>
                  <Text color={theme.light}>
                    {t("pages.auth.forget_password")}
                  </Text>
                  <Text ms={2} color={theme.blue}>
                    {t("pages.auth.reset")}
                  </Text>
                </Flex>
              </ChakraLink>

              <Button
                type="submit"
                textTransform="capitalize"
                bg={theme.blue}
                color={theme.light}
                _hover={{ bg: theme.blue }}
                isLoading={auth.isLoading}
              >
                {t("pages.auth.register")}
              </Button>

              <ChakraLink as={Link} to="/login" textTransform="capitalize">
                <Flex>
                  <Text color={theme.light}>
                    {t("pages.auth.have_an_account")}
                  </Text>
                  <Text ms={2} color={theme.blue}>
                    {t("pages.auth.login")}
                  </Text>
                </Flex>
              </ChakraLink>
            </Stack>
          </form>
        </Box>
      </Flex>
    </AuthWarpper>
  );
};

export default Register;
