import React from "react";
import {
  Button,
  Box,
  VStack,
  useToast,
  InputGroup,
  InputRightElement,
  Text,
  Alert,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { sendShippingCostsToEasyOrder } from "../../../store/easyOrder/easyOrderSlice";
import { FaShippingFast, FaLink } from "react-icons/fa";
import theme from "../../global/theme";

const EasyOrder = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const toast = useToast();

  const handleNavigate = () => {
    window.location.href = `https://seller.easy-orders.net/#/install-app?app_name=Safka&app_description=ecommerce&permissions=products:read,products:create,shipping_areas,orders:read,orders:create,orders:update,orders:delete,categories:read,categories:create,categories:update,categories:delete&app_icon=https://safka-eg.com/images/logo.png&callback_url=${process.env.REACT_APP_SAFKA_API}easy-order-link/${auth?.user?._id}&orders_webhook=${process.env.REACT_APP_SAFKA_API}easy-order-order/${auth?.user?._id}/receive&redirect_url=${process.env.REACT_APP_SAFKA_URL}`;

    toast({
      title: "جاري التحويل...",
      description: "يتم الآن تحويلك إلى إيزي أوردر لإتمام الإعداد.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSendShippingAreas = () => {
    dispatch(sendShippingCostsToEasyOrder());

    toast({
      title: "تم إرسال المناطق",
      description: "تم إرسال مناطق الشحن بنجاح إلى إيزي أوردر.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
  return (
    <>
      <Box mt={6} width="100%" maxW="560px" mx="auto">
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/rtTk_0pe4d8?si=Tb25GnXrzuWDv8Cs" //youtu.be/
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
      {auth.user.allow_easy_order ? (
        <Box textAlign="center" mt={8}>
          <VStack spacing={4}>
            <Button
              leftIcon={<FaLink />}
              colorScheme="teal"
              size="lg"
              onClick={handleNavigate}
            >
              الربط مع إيزي اوردر
            </Button>
            <Button
              leftIcon={<FaShippingFast />}
              colorScheme="teal"
              size="lg"
              onClick={handleSendShippingAreas}
            >
              إرسال المحافظات إلي إيزي اوردر
            </Button>
          </VStack>
        </Box>
      ) : (
        <Alert
          mt={6}
          bg={theme.primary}
          color={theme.light}
          mb={4}
          fontSize={22}
        >
          لتنشيط EasyOrder لحسابك يرجي التواصل مع الدعم
        </Alert>
      )}
    </>
  );
  // return <div>Comming Soon</div>;
};

export default EasyOrder;
