import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Alert,
  AlertIcon,
  Button,
  Spinner,
  Divider,
} from "@chakra-ui/react";
import theme from "../../../global/theme";
import SuggestProuct from "./SuggestProuct";
import ViewModal from "./ViewModal";
import Breadcrumbs from "../../../shared/breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSuggested } from "../../../../store/suggestions/suggestedSlice";
import Pagination from "../../../shared/pagination/Pagination";
import SuggestionsTable from "./SuggestionsTable";

const SuggestedProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.suggested);
  const auth = useSelector((state) => state.auth);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const [suggestion, setSuggestion] = useState(null);
  const [viewSuggestion, setViewSuggestion] = useState(false);
  const [showModal, setShowModal] = useState(false);
  console.log(suggestions);
  useEffect(() => {
    dispatch(getSuggested({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <div>
      <Breadcrumbs
        currentPage={"الإقتراحات"}
        pages={[{ name: `لوحة التحكم`, path: "/" }]}
      />
      {suggestions.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {suggestions.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}
      <Text mb={4}>
        نحن نسعى دائمًا لتحسين وتطوير منصتنا لتلبية احتياجاتكم ومتطلباتكم. لذلك،
        يسعدنا أن نقدم لكم ميزة جديدة تتيح لكم، كمسوقين، اقتراح منتجات جديدة
        لإدراجها في النظام. هذه الميزة تمكّنكم من المساهمة بآرائكم واقتراحاتكم،
        مما يساعدنا في توسيع تشكيلة المنتجات المتاحة وتحسين تجربتكم وتجربة
        عملائنا.
        <Divider marginBlock={6} />
        <strong>ملاحظات هامة:</strong>
        <br />
        - التحقق والمراجعة: سيتم مراجعة جميع الاقتراحات من قبل فريق الإدارة
        لدينا للتأكد من ملاءمتها وجودتها قبل إدراجها في النظام.
        <br />
        - المتابعة: يمكنك متابعة حالة اقتراحاتك من خلال قسم "اقتراحاتي" في
        حسابك.
        <Divider marginBlock={6} />
        نحن نقدر جهودكم ومساهماتكم في تحسين وتطوير منصتنا. إذا كان لديك أي أسئلة
        أو تحتاج إلى مساعدة إضافية، فلا تتردد في التواصل مع فريق الدعم الخاص
        بنا.
      </Text>
      <Button
        // bg={theme.primary}
        // border={`1px solid ${theme.primary}`}
        // color={theme.light}
        _hover={{ bg: theme.primary, color: theme.light }}
        me={3}
        marginBlock={3}
      >
        {auth.user.role === "admin" ? "المقترحات" : "إقتراحاتي"}
      </Button>
      {auth.user.role !== "admin" && (
        <Button
          bg={theme.primary}
          border={`1px solid ${theme.primary}`}
          color={theme.light}
          _hover={{ bg: theme.primary, color: theme.light }}
          me={3}
          marginBlock={3}
          onClick={() => setShowModal(true)}
        >
          إقتراح منتج
        </Button>
      )}

      {suggestions.isLoading ? (
        <Flex padding={6} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : suggestions.data?.itemsCount > 0 ? (
        <SuggestionsTable
          data={suggestions.data.data}
          page={page}
          // handleUpdate={(el) => setUpdateSuggestion(el)}
          handleView={(el) => {
            setViewSuggestion(el);
            setSuggestion(el);
          }}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            لا إقتراحات بعد
          </Text>
        </Flex>
      )}
      {suggestions.data?.itemsCount > 0 && !suggestions.isLoading && (
        <Flex justifyContent="flex-end">
          <Pagination
            page={page}
            itemsCount={suggestions.data?.itemsCount ?? 0}
            onChange={(page) => setPage(page)}
          />
        </Flex>
      )}
      {showModal && <SuggestProuct onClose={() => setShowModal(false)} />}
      {viewSuggestion && (
        <ViewModal
          onClose={() => {
            setViewSuggestion(false);
            setSuggestion(null);
          }}
          data={suggestion}
        />
      )}
    </div>
  );
};

export default SuggestedProducts;
