import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  Flex,
  Text,
  FormLabel,
  FormControl,
  Textarea,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import { updateCancelStatus } from "../../../../store/orders/ordersSlice";

import theme from "../../../global/theme";

const UpdateOrdersStatusModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      orders: data,
      status: data[0]?.status,
      holding_to: null,
    },
  });

  watch(["status"]);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={20} padding={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values) => {
            values.status = "cancelled";
            dispatch(updateCancelStatus(values))
              .unwrap()
              .then((_) => {
                document.querySelectorAll("button").forEach((el) => {
                  el.remove();
                });
                alert("تم تحديث حالة الطلبات بنجاح!");
                window.location.reload();
              })
              .catch((_) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            إلغاء الاوردر
          </ModalHeader>

          <FormControl mt={6}>
            <FormLabel
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("pages.orders.note")}
            </FormLabel>
            <Textarea
              bg={theme.bg}
              color={theme.dark}
              border="none"
              placeholder={"برجاء توضيح سبب إلغاء الاورد"}
              _placeholder={{ textTransform: "capitalize" }}
              {...register("reason_of_canceled", {
                maxLength: {
                  value: 150,
                  message: `${t("validation.max_length")} 150`,
                },
              })}
            />
            {errors.reason_of_canceled?.message && (
              <Text color="red.600" marginTop={2}>
                {errors.reason_of_canceled.message}
              </Text>
            )}
          </FormControl>

          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={orders.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={orders.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateOrdersStatusModal;
