import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  SimpleGrid,
  Flex,
  Text,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightElement,
  Divider,
  useToast,
  Image,
  Select,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import theme from "../../../global/theme";
import { updateSuggested } from "../../../../store/suggestions/suggestedSlice";

const ViewModal = ({ onClose, data }) => {
  const { t } = useTranslation();
  const products = useSelector((state) => state.suggested);
  const auth = useSelector((state) => state.auth);
  const toast = useToast({ position: "top", status: "success" });
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: data });

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values, event) => {
            dispatch(
              updateSuggested({
                id: data._id,
                values: {
                  status: getValues("status"),
                  note: getValues("note"),
                },
              })
            )
              .unwrap()
              .then((res) => {
                toast({
                  description: "تم تعديل الإقتراح بنجاح",
                });
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            {auth.user.role === "admin"
              ? "الموافقة أو الرفض"
              : "تفاصيل الإقتراح"}
          </ModalHeader>
          <ModalBody>
            {products.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {products.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  اسم المنتج
                </FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  color={theme.dark}
                  disabled={true}
                  border="none"
                  borderRadius={4}
                  placeholder="اسم المنتج"
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("name", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.name?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.name.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  سعر التكلفة المقترح
                </FormLabel>
                <Input
                  type="number"
                  disabled={true}
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder="سعر التكلفة المقترح"
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("sale_price")}
                />
                {errors.sale_price?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.sale_price.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  color={theme.light}
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t("pages.products.image")}
                </FormLabel>

                {typeof getValues("image") === "string" &&
                  getValues("image") !== "" && (
                    <>
                      <Image
                        src={`/suggested/${getValues("image")}`}
                        maxH={150}
                        mt={4}
                        alt="product image"
                      />
                    </>
                  )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.media_url")}
                </FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  disabled={true}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.products.media_url")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("media_url")}
                />
                {errors.media_url?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.media_url.message}
                  </Text>
                )}
              </FormControl>
            </SimpleGrid>

            <FormControl mt={6}>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                {t("pages.products.description")}
              </FormLabel>
              <Textarea
                bg={theme.bg}
                color={theme.dark}
                disabled={true}
                border="none"
                borderRadius={4}
                placeholder={t("pages.products.description")}
                _placeholder={{ textTransform: "capitalize" }}
                {...register("description")}
              />
              {errors.description?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.description.message}
                </Text>
              )}
            </FormControl>

            <Divider marginBlock={6} />

            <FormControl mt={6}>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                {t("pages.products.note")}
              </FormLabel>
              <Textarea
                bg={theme.bg}
                color={theme.dark}
                border="none"
                disabled={auth.user.role !== "admin"}
                borderRadius={4}
                placeholder={t("pages.products.note")}
                _placeholder={{ textTransform: "capitalize" }}
                {...register("note")}
              />
              {errors.note?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.note.message}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                حالة الإقتراح
              </FormLabel>
              <Select
                border="none"
                disabled={auth.user.role !== "admin"}
                color={theme.dark}
                bg={theme.bg}
                {...register("status", {
                  required: `${t("validation.required")}`,
                })}
              >
                <option style={{ color: theme.dark }} value="pending">
                  معلق
                </option>
                <option style={{ color: theme.dark }} value="rejected">
                  رفض
                </option>
                <option style={{ color: theme.dark }} value="approved">
                  قبول
                </option>
              </Select>
              {errors.status?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.status.message}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              {auth.user.role === "admin" && (
                <Button
                  type="submit"
                  rightIcon={<FiIcons.FiSave />}
                  color="white"
                  bg="green"
                  paddingInline={4}
                  paddingBlock={2}
                  height="auto"
                  textTransform="capitalize"
                  isLoading={products.isLoading}
                  _hover={{ background: "green" }}
                >
                  تحديث الإقتراح
                </Button>
              )}
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={products.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ViewModal;
