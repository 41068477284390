import React from "react";

import { FooterWrapper } from "./FooterStyle";

const Footer = () => {
  return (
    <FooterWrapper>
      powered by{" "}
      <a
        href="https://www.facebook.com/profile.php?id=100083218244241&mibextid=ZbWKwL"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          fontSize: "25px",
          marginLeft: "5px",
          background: "aliceblue",
          padding: "10px",
          borderRadius: "12px",
          fontWeight: "900",
          letterSpacing: "5px",
        }}
      >
        Safka
      </a>
    </FooterWrapper>
  );
};

export default Footer;
