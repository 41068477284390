import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Initial state
const initialState = {
  isLoading: false,
  data: {},
  popular: {},
  min: {},
  low: {},
  product: null,
  errors: [],
};

// Thunks
export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (args, thunkApi) => {
    try {
      const {
        page = 1,
        size = 10,
        query = "",
        filter = JSON.stringify({}),
      } = args;
      const { data } = await axios.get(
        `/api/get_products/${
          thunkApi.getState().auth.user._id
        }?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const getPopularProducts = createAsyncThunk(
  "products/getPopularProducts",
  async (args, thunkApi) => {
    try {
      const {
        page = 1,
        size = 10,
        query = "",
        filter = JSON.stringify({}),
      } = args;
      const { data } = await axios.get(
        `/api/popular_products/${
          thunkApi.getState().auth.user._id
        }?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const getMinProducts = createAsyncThunk(
  "products/getMinProducts",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/min_products?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const getMostInactiveProducts = createAsyncThunk(
  "products/getMostInactiveProducts",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/getMostInactiveProducts?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const getProductById = createAsyncThunk(
  "products/getProductById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/products/${args.productId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_product", args, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const addFavoriteProduct = createAsyncThunk(
  "products/addFavoriteProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/myFavorites", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const removeFavoriteProduct = createAsyncThunk(
  "products/removeFavoriteProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `/api/myFavorites/${args.productId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_product/${args.id}`,
        args.values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateProductStatus = createAsyncThunk(
  "products/updateProductStatus",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.patch(
        `/api/update_product/${args.id}`,
        args.values,
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateAccessProduct = createAsyncThunk(
  "products/updateAccessProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_access_product/${args._id}`,
        args,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle getProducts
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      })
      // Handle getPopularProducts
      .addCase(getPopularProducts.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getPopularProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.popular = action.payload;
        state.errors = [];
      })
      .addCase(getPopularProducts.rejected, (state, action) => {
        state.isLoading = false;

        state.errors = action.payload.errors;
      })
      // Handle getMinProducts
      .addCase(getMinProducts.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getMinProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.min = action.payload;
        state.errors = [];
      })
      .addCase(getMinProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.min = {};
        state.errors = action.payload.errors;
      })
      .addCase(getMostInactiveProducts.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getMostInactiveProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.low = action.payload;
        state.errors = [];
      })
      .addCase(getMostInactiveProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.low = {};
        state.errors = action.payload.errors;
      })

      // Handle getProductById
      .addCase(getProductById.pending, (state) => {
        state.isLoading = true;
        state.product = null;
        state.errors = [];
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.product = action.payload.product;
        state.errors = [];
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.isLoading = false;
        state.product = null;
        state.errors = action.payload.errors;
      })

      // Handle createProduct
      .addCase(createProduct.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount++;
        if (state.data.data && state.data.data.length < 10) {
          state.data.data.push(action.payload.data);
        }
        state.errors = [];
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle addFavoriteProduct
      .addCase(addFavoriteProduct.pending, (state) => {
        state.errors = [];
      })
      .addCase(addFavoriteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.data.data) {
          state.data.data = state.data.data.map((el) =>
            el._id === action.payload.data.product
              ? { ...el, isFavorite: true }
              : el
          );
        }
        state.errors = [];
      })
      .addCase(addFavoriteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle removeFavoriteProduct
      .addCase(removeFavoriteProduct.pending, (state) => {
        state.errors = [];
      })
      .addCase(removeFavoriteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.data.data) {
          state.data.data = state.data.data.map((el) =>
            el._id === action.payload.data ? { ...el, isFavorite: false } : el
          );
        }
        state.errors = [];
      })
      .addCase(removeFavoriteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle updateProduct
      .addCase(updateProduct.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.data.data) {
          state.data.data = state.data.data.map((el) =>
            el._id === action.payload.data._id ? action.payload.data : el
          );
        }
        state.errors = [];
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle updateProductStatus
      .addCase(updateProductStatus.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateProductStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.data.data) {
          state.data.data = state.data.data.map((el) =>
            el._id === action.payload.data._id ? action.payload.data : el
          );
        }
        state.errors = [];
      })
      .addCase(updateProductStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle updateAccessProduct
      .addCase(updateAccessProduct.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateAccessProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.data.data) {
          state.data.data = state.data.data.map((el) =>
            el._id === action.payload.data._id ? action.payload.data : el
          );
        }
        state.errors = [];
      })
      .addCase(updateAccessProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default productsSlice.reducer;
