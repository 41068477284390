import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Flex,
  Text,
  Stack,
  Alert,
  AlertIcon,
  Select,
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import { createSupport } from '../../../../store/supports/supportsSlice';
import theme from '../../../global/theme';


const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const supports = useSelector(state => state.supports);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <Modal isOpen={true} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent borderRadius={4} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit((values) => {
          dispatch(createSupport(values)).unwrap().then(_ => {
            onClose();
          });
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('pages.supports.create_support')}
          </ModalHeader>
          <ModalBody>

            {supports.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
              <AlertIcon />
              {supports.errors?.map((error, index) => (
                <Text key={index}>{error?.msg}</Text>
              ))}
            </Alert>}

            <Stack spacing={4}>
              <FormControl>
                <FormLabel color={theme.light} fontWeight="bold" textTransform="capitalize">
                  {t('pages.supports.phone')}
                </FormLabel>
                <Input type="tel" bg={theme.light} border="none" borderRadius={4}
                  placeholder={t('pages.supports.phone')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("phone", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.phone?.message &&
                  <Text color="red.600" marginTop={2}>{errors.phone.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel color={theme.light} fontWeight="bold" textTransform="capitalize">
                  {t('pages.supports.description')}
                </FormLabel>
                <Textarea bg={theme.light} border="none" borderRadius={4}
                  placeholder={t('pages.supports.description')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("description", {
                    required: `${t('validation.required')}`,
                    maxLength: {
                      value: 150,
                      message: `${t('validation.max_length')} 150`
                    }
                  })}
                />
                {errors.description?.message &&
                  <Text color="red.600" marginTop={2}>{errors.description.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel color={theme.light} fontWeight="bold" textTransform="capitalize">
                  اختر تاجر او مسوق
                </FormLabel>
                <Select bg={theme.light} border="none" borderRadius={4}
                  placeholder="اختر تاجر او مسوق" _placeholder={{ textTransform: 'capitalize' }}
                  {...register("for", {
                    required: `${t('validation.required')}`,
                  })}
                >
                  <option value="merchant">تاجر</option>
                  <option value="marketer">مسوق</option>
                </Select>
                {errors.for?.message &&
                  <Text color="red.600" marginTop={2}>{errors.for.message}</Text>}
              </FormControl>
            </Stack>

          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={supports.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={supports.isLoading}
                _hover={{ background: 'red.600' }}
                onClick={onClose}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateModal