import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Button, Text, SimpleGrid, Alert, AlertIcon } from '@chakra-ui/react';


import InputSearch from '../../shared/inputSearch/InputSearch';
import Pagination from '../../shared/pagination/Pagination';
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs';
import CategoriesTable from './CategoriesTable';
import CreateModal from './actions/CreateModal';
import UpdateModal from './actions/UpdateModal';
import DeleteModal from './actions/DeleteModal';
import Filter from './actions/Filter';

import { getCategories } from '../../../store/categories/categoriesSlice';

import { CategoriesWrapper } from './CategoriesStyle';

import theme from '../../global/theme';

const Categories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const [showUpdateCategory, setShowUpdateCategory] = useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);

  useEffect(() => {
    dispatch(getCategories({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <CategoriesWrapper>
      <Breadcrumbs currentPage={t('pages.categories.categories')} pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]} />

      {categories.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
        <AlertIcon />
        {categories.errors?.map((error, index) => (
          <Text key={index}>{error?.msg}</Text>
        ))}
      </Alert>}

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>

          <Button bg="red.600" textTransform="uppercase"
            fontWeight="bold" fontSize={18} marginInlineEnd={4} color="white" _hover={{ bg: 'red.600' }}
            onClick={() => setShowCreateCategory(true)}
          >
            {t('general.create')}
          </Button>
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={categories.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) => setFilter(values)}
            handleClearFilter={() => setFilter(JSON.stringify({}))}
            disablesBtns={categories.isLoading}
          />
        </Flex>
      </SimpleGrid>

      {categories.data?.itemsCount > 0 ? (
        <CategoriesTable
          data={categories.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateCategory(el)}
          handleDelete={(el) => setShowDeleteCategory(el)}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} boxShadow={theme.shadow} height={200} alignItems="center"
          justifyContent="center" borderRadius={16}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.categories.no_categories')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={categories.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateCategory && <CreateModal onClose={() => setShowCreateCategory(false)} />}
      {showUpdateCategory && <UpdateModal data={showUpdateCategory} onClose={() => setShowUpdateCategory(false)} />}
      {showDeleteCategory && <DeleteModal data={showDeleteCategory} onClose={() => setShowDeleteCategory(false)} />}
    </CategoriesWrapper>
  )
}

export default Categories