import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Box,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import InputSearch from "../../shared/inputSearch/InputSearch";
import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import RequestsTable from "./RequestsTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import Filter from "./actions/Filter";

import { getRequests } from "../../../store/requests/requestsSlice";
import { getAccounts } from "../../../store/accounts/accountsSlice";

import { RequestsWrapper } from "./RequestsStyle";

import theme from "../../global/theme";
import RequestStatusModal from "./actions/RequestStatusModal";

const Requests = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const accounts = useSelector((state) => state.accounts);
  const requests = useSelector((state) => state.requests);

  const [showCreateRequest, setShowCreateRequest] = useState(false);
  const [showUpdateRequest, setShowUpdateRequest] = useState(false);
  const [showOpenRequest, setShowOpenRequest] = useState(false);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const defaultFilter = {};
  const [filter, setFilter] = useState(JSON.stringify(defaultFilter));

  const [accountsFilter] = useState(JSON.stringify({}));
  useEffect(() => {
    dispatch(getAccounts({ page: 1, query: "", filter: accountsFilter }));
  }, [dispatch, filter, accountsFilter]);

  useEffect(() => {
    dispatch(getRequests({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <RequestsWrapper>
      <Breadcrumbs
        currentPage={t("pages.requests.requests")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      <Alert bg={theme.primary} color={theme.light} mb={4} fontSize={22}>
        يتم مراجعة طلب السحب خلال 24 ساعه والحد الأدنى للسحب ٥٠ جنية.
      </Alert>

      {requests.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {requests.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      {accounts.data.data &&
        accounts.data.data.map((el) => (
          <SimpleGrid columns={{ sm: 1, lg: 2, xl: 5 }} spacing={6}>
            <Flex
              boxShadow={theme.shadow}
              bg={theme.light}
              padding={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex justifyContent="space-between">
                <Box color="#1C3FAA">
                  <FiIcons.FiDollarSign size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={30} mt={4} color={theme.dark}>
                  {el?.pending}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={16}
                  textTransform="capitalize"
                  color={theme.dark}
                >
                  رصيد معلق
                </Text>
              </Box>
            </Flex>

            <Flex
              boxShadow={theme.shadow}
              bg={theme.light}
              padding={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex justifyContent="space-between">
                <Box color="#F78B00">
                  <FiIcons.FiDollarSign size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={30} mt={4} color={theme.dark}>
                  {el?.preparing}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={16}
                  textTransform="capitalize"
                  color={theme.dark}
                >
                  رصيد جار التحضير
                </Text>
              </Box>
            </Flex>

            <Flex
              boxShadow={theme.shadow}
              bg={theme.light}
              padding={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex justifyContent="space-between">
                <Box color="#F44336">
                  <FiIcons.FiDollarSign size={50} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={30} mt={4} color={theme.dark}>
                  {el?.shipped}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={16}
                  textTransform="capitalize"
                  color={theme.dark}
                >
                  رصيد قيد الشحن
                </Text>
              </Box>
            </Flex>

            <Flex
              boxShadow={theme.shadow}
              bg={theme.light}
              padding={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex justifyContent="space-between">
                <Box color="#b111a7">
                  <FiIcons.FiDollarSign size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={30} mt={4} color={theme.dark}>
                  {el?.available}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={16}
                  textTransform="capitalize"
                  color={theme.dark}
                >
                  رصيد تم التوصيل
                </Text>
              </Box>
            </Flex>
            <Flex
              boxShadow={theme.shadow}
              bg={theme.light}
              padding={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex justifyContent="space-between">
                <Box color="#b111a7">
                  <FiIcons.FiDollarSign size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={30} mt={4} color={theme.dark}>
                  {el?.availableToWithdraw}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={16}
                  textTransform="capitalize"
                  color={theme.dark}
                >
                  رصيد قابل للسحب
                </Text>
              </Box>
            </Flex>
            {auth.user.role == "merchant" && (
              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#b111a7">
                    <FiIcons.FiDollarSign size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={30}
                    mt={4}
                    color={theme.dark}
                  >
                    {el?.skipping}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={16}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    رصيد جار الإسترجاع
                  </Text>
                </Box>
              </Flex>
            )}
          </SimpleGrid>
        ))}

      <SimpleGrid
        mt={8}
        columns={{ sm: 1, md: 2 }}
        justifyContent="space-between"
      >
        <Flex mb={8}>
          {auth.user.role !== "admin" && (
            <Button
              bg={theme.blue}
              textTransform="uppercase"
              fontWeight="bold"
              fontSize={18}
              marginInlineEnd={4}
              color="white"
              _hover={{ bg: theme.blue }}
              onClick={() => setShowCreateRequest(true)}
            >
              طلب سحب
            </Button>
          )}
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={accounts.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) =>
              setFilter(
                JSON.stringify({
                  ...defaultFilter,
                  ...values,
                })
              )
            }
            handleClearFilter={() => setFilter(JSON.stringify(defaultFilter))}
            disablesBtns={requests.isLoading}
          />
        </Flex>
      </SimpleGrid>

      {requests.data?.itemsCount > 0 ? (
        <RequestsTable
          data={requests.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateRequest(el)}
          handleOpen={(el) => setShowOpenRequest(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.requests.no_requests")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={requests.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateRequest && (
        <CreateModal onClose={() => setShowCreateRequest(false)} />
      )}
      {showUpdateRequest && (
        <UpdateModal
          data={showUpdateRequest}
          onClose={() => setShowUpdateRequest(false)}
        />
      )}
      {showOpenRequest && (
        <RequestStatusModal
          data={showOpenRequest}
          onClose={() => setShowOpenRequest(false)}
        />
      )}
    </RequestsWrapper>
  );
};

export default Requests;
