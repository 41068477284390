import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

export const getRules = createAsyncThunk(
  "rules/getRules",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const response = await axios.get(
        `/api/get_rules?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createRule = createAsyncThunk(
  "rules/createRule",
  async (args, thunkApi) => {
    try {
      const response = await axios.post("/api/create_rule", args, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateRule = createAsyncThunk(
  "rules/updateRule",
  async (args, thunkApi) => {
    try {
      const response = await axios.put(`/api/update_rule/${args._id}`, args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const deleteRule = createAsyncThunk(
  "rules/deleteRule",
  async (args, thunkApi) => {
    try {
      const response = await axios.delete(`/api/delete_rule/${args._id}`, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const rulesSlice = createSlice({
  name: "rules",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get rules
      .addCase(getRules.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getRules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getRules.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors || [];
      })

      // Create rule
      .addCase(createRule.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createRule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount = (state.data.itemsCount || 0) + 1;
        if (state.data.data?.length < 10) {
          state.data.data = [...(state.data.data || []), action.payload];
        }
        state.errors = [];
      })
      .addCase(createRule.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Update rule
      .addCase(updateRule.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateRule.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.data.data?.findIndex(
          (el) => el._id === action.payload._id
        );
        if (index !== -1) {
          state.data.data[index] = action.payload;
        }
        state.errors = [];
      })
      .addCase(updateRule.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Delete rule
      .addCase(deleteRule.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteRule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.data =
          state.data.data?.filter((el) => el._id !== action.payload._id) || [];
        state.errors = [];
      })
      .addCase(deleteRule.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      });
  },
});

export default rulesSlice.reducer;
