import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { Box, Button, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  updateProductsOrder,
} from "../../../../store/products/productsOrderSlice";

const ProductsOrder = () => {
  const productsOrder = useSelector((state) => state.productsOrder);
  const [products, setProducts] = useState([]);
  const toast = useToast({
    duration: 5000,
    status: "success",
    position: "top",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProducts());
  }, []);
  useEffect(() => {
    setProducts(productsOrder?.data);
  }, [productsOrder?.data]);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(products);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the local state immediately to reflect the new order visually
    setProducts(items);
  };

  const handleSaveOrder = async () => {
    const updatedProducts = products.map((product, index) => ({
      ...product,
      order: index, // Assign the new order index
    }));

    try {
      // Send the updated products array to the backend for saving
      dispatch(updateProductsOrder(updatedProducts));
      // Toast after the update is successful
      toast({
        description: "تم حفظ الترتيب بنجاح",
      });
    } catch (error) {
      console.error("Error updating order", error);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {/* Button to save order */}
      <Button m="4" colorScheme="blue" onClick={handleSaveOrder}>
        حفظ الترتيب
      </Button>
      <Droppable droppableId="products">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {products.map((product, index) => (
              <Draggable
                key={product._id}
                draggableId={product._id}
                index={index}
              >
                {(provided) => (
                  <Box
                    bg="white"
                    p="2"
                    borderRadius="md"
                    shadow="md"
                    mb="2"
                    cursor="grab"
                    _hover={{ bg: "gray.200" }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {product.name}
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProductsOrder;
