import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

// Thunks
export const getWarehouses = createAsyncThunk(
  "warehouses/getWarehouses",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_warehouses?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createWarehouse = createAsyncThunk(
  "warehouses/createWarehouse",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_warehouse", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateWarehouse = createAsyncThunk(
  "warehouses/updateWarehouse",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_warehouse/${args.id}`,
        args.values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const deleteWarehouse = createAsyncThunk(
  "warehouses/deleteWarehouse",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(`/api/delete_warehouse/${args._id}`, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

// Slice
const warehousesSlice = createSlice({
  name: "warehouses",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get warehouses
      .addCase(getWarehouses.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getWarehouses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getWarehouses.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors || [];
      })

      // Create warehouse
      .addCase(createWarehouse.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount = (state.data.itemsCount || 0) + 1;
        if (state.data?.data?.length < 10) {
          state.data.data = [...(state.data.data || []), action.payload.data];
        }
        state.errors = [];
      })
      .addCase(createWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Update warehouse
      .addCase(updateWarehouse.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.data.data?.findIndex(
          (el) => el._id === action.payload.data._id
        );
        if (index !== -1) {
          state.data.data[index] = action.payload.data;
        }
        state.errors = [];
      })
      .addCase(updateWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Delete warehouse
      .addCase(deleteWarehouse.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.data = state.data.data?.filter(
          (el) => el._id !== action.payload.data._id
        );
        state.errors = [];
      })
      .addCase(deleteWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      });
  },
});

export default warehousesSlice.reducer;
