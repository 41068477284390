import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { FaWhatsapp, FaPhone, FaSms, FaGift } from "react-icons/fa";

import { updateOrderCallAction } from "../../../store/orders/ordersSlice";

import UpdateModal from "./actions/UpdateModal";
import UpdateOrdersStatusModal from "./actions/UpdateOrdersStatusModal";
import CancelOrdersStatusModal from "./actions/CancelOrdersStatusModal";

import {
  Flex,
  Stack,
  Text,
  Box,
  SimpleGrid,
  Button,
  Divider,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import { cities, governorates } from "../../../utilities/places";

import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import Table from "../../shared/table/Table";

import { getOrders } from "../../../store/orders/ordersSlice";

import theme from "../../global/theme";
import { getProducts } from "../../../store/products/productsSlice";
import UpsellModal from "./actions/UpsellModal";
import OrderNotes from "./OrderNotes";

const OrderDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orders);

  const [showUpdateOrder, setShowUpdateOrder] = useState(false);
  const [showUpdateOrdersStatus, setShowUpdateOrdersStatus] = useState(false);
  const [showCancelOrdersStatus, setShowCancelOrdersStatus] = useState(false);
  const [showReverseOrdersStatus, setShowReverseOrdersStatus] = useState(false);
  const [showUpsell, setShowUpsell] = useState(false);
  const [phoneData, setPhoneData] = useState({});
  const [disabled, setDisabled] = useState(false);

  const { orderId } = useParams();

  const currentOrder = orders.data?.data && orders.data.data[0];

  const generateWhatsappMessage = (serialNumber, clientPhone) => {
    const phone = "2" + clientPhone;
    const step1 = `اهلا بحضرتك عميلنا العزيز بنكلمك بخصوص تاكيد اوردر كود ${serialNumber}`;
    const step2 = `${currentOrder.items.map(
      (item, idx) =>
        `(${item.qty}) قطعة ${item.product?.name} - ${
          item.product.properties.find((it) => it._id === item.property)?.key
        } ${currentOrder.items?.length === idx + 1 ? "" : "//"} `
    )}`;
    const addr = `علي عنوان: ${currentOrder?.client_address}, ${
      cities.find((city) => +city.id === currentOrder?.city)?.city_name_ar
    }, ${
      governorates.find(
        (gov) => +gov.id === currentOrder?.shipping_governorate?.governorate
      )?.governorate_name_ar
    },`;
    const step3 = `الاجمالي المطلوب دفعه ${currentOrder.total} جنية.`;
    const step4 = `لتاكيد الاوردر وخروجة للشحن ارسل رقم (1)`;
    const step5 = `للتعديل على الطلب ارسل الرقم (2)`;
    const message = `${step1}\n\n${step2}\n${addr}\n${step3}\n\n${step4}\n${step5}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
      phone
    )}&text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };

  const generateSmsMessage = (serialNumber, clientPhone) => {
    const phone = "2" + clientPhone;
    const step1 = `اهلا بحضرتك عميلنا العزيز بنكلمك بخصوص تاكيد اوردر كود ${serialNumber}`;
    const step2 = `${currentOrder.items.map(
      (item, idx) =>
        `(${item.qty}) قطعة ${item.product?.name} - ${
          item.product.properties.find((it) => it._id === item.property)?.key
        } ${currentOrder.items?.length === idx + 1 ? "" : "//"} `
    )}`;
    const step3 = `الاجمالي المطلوب دفعه ${currentOrder.total} جنية.`;
    const step4 = `لتاكيد الاوردر وخروجة للشحن ارسل رقم (1)`;
    const step5 = `للتعديل على الطلب ارسل الرقم (2)`;
    const message = `${step1}\n\n${step2}\n${step3}\n\n${step4}\n${step5}`;
    const smsUrl = `sms:${encodeURIComponent(phone)}?body=${encodeURIComponent(
      message
    )}`;
    window.location.href = smsUrl;
  };
  const { hash, pathname } = useLocation();
  useEffect(() => {
    if (hash === "#notes") {
      const notesElement = document.getElementById("notes");
      if (notesElement) {
        notesElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);
  useEffect(() => {
    setDisabled(false);
    dispatch(
      getOrders({
        page: 1,
        query: "",
        filter: JSON.stringify({ _id: orderId }),
      })
    );
  }, [dispatch, orderId]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStatus = searchParams.get("status") || "";
  const query = searchParams.get("query") || "";

  if (!currentOrder) {
    return <h1>waitng ...</h1>;
  }
  const totalCost = () => {
    let total = 0;
    currentOrder.items.forEach((item) => {
      total += item.total_sale_price;
    });
    return total;
  };
  return (
    <>
      <Breadcrumbs
        currentPage={t("pages.orders.orders")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {orders.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {orders.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      {auth.user.role === "admin" && (
        <SimpleGrid columns={{ base: 2, md: 6 }} spacing={6} mb={8}>
          {(currentOrder.status === "pending" ||
            currentOrder.status === "holding") && (
            <Button
              type="button"
              bg={theme.success}
              color={theme.light}
              onClick={() => setShowUpdateOrder(currentOrder)}
            >
              تعديل الطلب
            </Button>
          )}
          {auth.user?.rule?.permissions?.includes("manage_orders") &&
            auth.user?.rule?.name === "superadmin" && (
              <Button
                variant="outline"
                type="button"
                ms={4}
                color={theme.error}
                borderColor={theme.error}
                onClick={setShowReverseOrdersStatus}
              >
                إرجاع حالة الطلب
              </Button>
            )}
          <Button
            variant="outline"
            type="button"
            ms={4}
            color={theme.success}
            borderColor={theme.success}
            onClick={setShowUpdateOrdersStatus}
            isDisabled={
              currentOrder.status === "pending" && !currentOrder?.city
            }
          >
            تغيير حالة الطلب
          </Button>
          <Button
            bg={theme.blue}
            color={theme.light}
            ms={4}
            onClick={() =>
              navigate(
                `/orders/order_details/${currentOrder._id}?status=${currentStatus}&query=${query}#notes`
              )
            }
          >
            <Box position="relative">
              <span>ملاحظات الطلب</span>
              {currentOrder.replies.length > 0 && (
                <Box
                  position="absolute"
                  top="-15px"
                  left="-1px"
                  w="15px"
                  h="15px"
                  bg={theme.error}
                  color={theme.light}
                  borderRadius="50%"
                  fontSize="6px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {currentOrder.replies?.length ?? 0}
                </Box>
              )}
            </Box>
          </Button>
        </SimpleGrid>
      )}
      {auth.user.role === "admin" &&
        currentOrder.status === "pending" &&
        !currentOrder?.city && (
          <Alert bg={theme.primary} color={theme.light} mb={4} fontSize={22}>
            برجاء إضافة مدينة الشحن للطلب قبل تغيير حالة الطلب
          </Alert>
        )}
      {auth.user.role === "marketer" && (
        <Flex mb={8}>
          {currentOrder.status === "pending" &&
            // currentOrder?.replies?.length === 0 &&
            // currentOrder?.upsell1_clicked == 0 &&
            // currentOrder?.upsell2_clicked == 0 &&
            // currentOrder?.sms1_clicked == 0 &&
            // currentOrder?.sms2_clicked == 0 &&
            // currentOrder?.phone1_clicked == 0 &&
            // currentOrder?.phone2_clicked == 0 &&
            currentOrder?.whats1_clicked == 0 &&
            currentOrder?.whats2_clicked == 0 && (
              <>
                <Button
                  type="button"
                  bg={"red"}
                  color={theme.light}
                  onClick={() => setShowCancelOrdersStatus(currentOrder)}
                >
                  إلغاء الاوردر
                </Button>
                <Button
                  ms={4}
                  type="button"
                  bg={theme.success}
                  color={theme.light}
                  onClick={() => setShowUpdateOrder(currentOrder)}
                >
                  تعديل الطلب
                </Button>
              </>
            )}
        </Flex>
      )}

      <Box dir="rtl">
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6} mb={8}>
          <Box display="block" w="100%" color={theme.blue} fontSize="18px">
            <Box mb={2}>
              كود المسوق:{" "}
              <span style={{ color: theme.error }}>
                {currentOrder.marketer?.code}
              </span>
            </Box>
            <Box mb={2}>
              اسم المسوق:{" "}
              <span style={{ color: theme.error }}>
                {currentOrder.marketer?.name}
              </span>
            </Box>
            <Box mb={2}>
              الصفحة:{" "}
              <span style={{ color: theme.error }}>
                {currentOrder.page_name}
              </span>
            </Box>
            {currentOrder?.moderator?.code && (
              <>
                <Box mb={2}>
                  كود المودريتر:{" "}
                  <span style={{ color: theme.error }}>
                    {currentOrder.moderator?.code}
                  </span>
                </Box>
                <Box mb={2}>
                  اسم المودريتر:{" "}
                  <span style={{ color: theme.error }}>
                    {currentOrder.moderator?.name}
                  </span>
                </Box>
              </>
            )}
          </Box>

          <Box fontWeight="bold" color={theme.dark}>
            <Box>كود الاوردر: {currentOrder?.serial_number}</Box>
            <Box>
              تاريخ الانشاء:{" "}
              {new Date(currentOrder?.created_at).toLocaleString()}
            </Box>
            <Box>
              اخر تحديث: {new Date(currentOrder?.updated_at).toLocaleString()}
            </Box>
            {currentOrder?.isAddedByAdmin && <Box>مضاف عن طريق الأدمن</Box>}
            <Box mt={2}>
              حالة الطلب:{" "}
              <Box
                background={
                  currentOrder.status === "pending"
                    ? "yellow.400"
                    : currentOrder.status === "preparing"
                    ? "orange"
                    : currentOrder.status === "shipped"
                    ? "#0094B7"
                    : currentOrder.status === "available" ||
                      currentOrder.status === "collected"
                    ? "green"
                    : "red"
                }
                display="inline-block"
                as="span"
                color={theme.light}
                paddingInline={2}
                paddingBlock={1}
                borderRadius="5px"
              >
                {t(`pages.orders.${currentOrder.status}`)}
              </Box>
            </Box>
            {currentOrder.canceling_reason && (
              <>
                <Box mt={2}>
                  <Box
                    display="inline-block"
                    as="span"
                    paddingInline={2}
                    paddingBlock={1}
                    borderRadius="5px"
                    bg={"red"}
                    color={"white"}
                  >
                    سبب الإلغاء:{" "}
                  </Box>
                  <Box
                    display="inline-block"
                    as="span"
                    paddingInline={2}
                    paddingBlock={1}
                    borderRadius="5px"
                  >
                    {t(currentOrder.canceling_reason)}
                  </Box>
                </Box>
                <Box mt={2}>
                  توضيح الإلغاء:{" "}
                  <Box
                    display="inline-block"
                    as="span"
                    paddingInline={2}
                    paddingBlock={1}
                    borderRadius="5px"
                  >
                    {currentOrder.canceling_reason_note}
                  </Box>
                </Box>
              </>
            )}
            {currentOrder.reason_of_canceled && (
              <>
                <Box mt={2}>
                  توضيح الإلغاء:{" "}
                  <Box
                    display="inline-block"
                    as="span"
                    paddingInline={2}
                    paddingBlock={1}
                    borderRadius="5px"
                  >
                    {currentOrder.reason_of_canceled}
                  </Box>
                </Box>
              </>
            )}
            {currentOrder.skipping_reason && (
              <>
                <Box mt={2}>
                  <Box
                    display="inline-block"
                    as="span"
                    paddingInline={2}
                    paddingBlock={1}
                    borderRadius="5px"
                    bg={"red"}
                    color={"white"}
                  >
                    سبب الإرجاع:{" "}
                  </Box>
                  <Box
                    display="inline-block"
                    as="span"
                    paddingInline={2}
                    paddingBlock={1}
                    borderRadius="5px"
                  >
                    {t(currentOrder.skipping_reason)}
                  </Box>
                </Box>
                <Box mt={2}>
                  توضيح السبب:{" "}
                  <Box
                    display="inline-block"
                    as="span"
                    paddingInline={2}
                    paddingBlock={1}
                    borderRadius="5px"
                  >
                    {currentOrder.skipping_reason_note}
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Stack spacing={2}>
            <Text
              color={theme.dark}
              fontWeight="bold"
              textTransform="capitalize"
            >
              الاسم : {currentOrder.client_name}
            </Text>
            <Text
              fontWeight="bold"
              textTransform="capitalize"
              color={theme.dark}
            >
              رقم الهاتف :{currentOrder.client_phone1}
              {auth.user.role === "admin" && (
                <>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateOrderCallAction({
                          _id: currentOrder._id,
                          action_name: "whats1_clicked",
                        })
                      )
                        .unwrap()
                        .then((_) => {
                          generateWhatsappMessage(
                            currentOrder.serial_number,
                            currentOrder.client_phone1
                          );
                        });
                    }}
                    display="inline-block"
                    marginInline={2}
                    color={theme.primary}
                  >
                    <FaWhatsapp size={23} />
                    {currentOrder.whats1_clicked > 0 && (
                      <Box color={theme.error}>
                        {currentOrder.whats1_clicked}
                      </Box>
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      setPhoneData({
                        phone: currentOrder.client_phone1,
                        serialNumber: currentOrder.serial_number,
                        action_name: "upsell1_clicked",
                      });
                      setShowUpsell(true);
                    }}
                    display="inline-block"
                    marginInline={2}
                    color={theme.primary}
                  >
                    <FaGift size={23} />
                    {currentOrder.upsell1_clicked > 0 && (
                      <Box color={theme.error}>
                        {currentOrder.upsell1_clicked}
                      </Box>
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateOrderCallAction({
                          _id: currentOrder._id,
                          action_name: "phone1_clicked",
                        })
                      )
                        .unwrap()
                        .then((_) => {
                          window.location.href = `tel:${currentOrder.client_phone1}`;
                        });
                    }}
                    display="inline-block"
                    marginInline={2}
                    color={theme.blue}
                  >
                    <FaPhone size={23} />
                    {currentOrder.phone1_clicked > 0 && (
                      <Box color={theme.error}>
                        {currentOrder.phone1_clicked}
                      </Box>
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateOrderCallAction({
                          _id: currentOrder._id,
                          action_name: "sms1_clicked",
                        })
                      )
                        .unwrap()
                        .then((_) => {
                          generateSmsMessage(
                            currentOrder.serial_number,
                            currentOrder.client_phone1
                          );
                        });
                    }}
                    display="inline-block"
                    marginInline={2}
                    color={theme.primary}
                  >
                    <FaSms size={23} />
                    {currentOrder.sms1_clicked > 0 && (
                      <Box color={theme.error}>{currentOrder.sms1_clicked}</Box>
                    )}
                  </Button>
                </>
              )}
            </Text>
            <Text
              fontWeight="bold"
              textTransform="capitalize"
              color={theme.dark}
            >
              رقم الهاتف الثاني: {currentOrder.client_phone2 || "-"}
              {auth.user.role === "admin" && (
                <>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateOrderCallAction({
                          _id: currentOrder._id,
                          action_name: "whats2_clicked",
                        })
                      )
                        .unwrap()
                        .then((_) => {
                          generateWhatsappMessage(
                            currentOrder.serial_number,
                            currentOrder.client_phone2
                          );
                        });
                    }}
                    display="inline-block"
                    marginInline={2}
                    color={theme.primary}
                  >
                    <FaWhatsapp size={23} />
                    {currentOrder.whats2_clicked > 0 && (
                      <Box color={theme.error}>
                        {currentOrder.whats2_clicked}
                      </Box>
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      // dispatch(
                      //   updateOrderCallAction({
                      //     _id: currentOrder._id,
                      //     action_name: "upsell2_clicked",
                      //   })
                      // )
                      //   .unwrap()
                      //   .then((_) => {
                      //     generateUpsellMessage(
                      //       currentOrder.serial_number,
                      //       currentOrder.client_phone2
                      //     );
                      //   });
                      setPhoneData({
                        phone: currentOrder.client_phone2,
                        serialNumber: currentOrder.serial_number,
                        action_name: "upsell2_clicked",
                      });
                      setShowUpsell(true);
                    }}
                    display="inline-block"
                    marginInline={2}
                    color={theme.primary}
                  >
                    <FaGift size={23} />
                    {currentOrder.upsell2_clicked > 0 && (
                      <Box color={theme.error}>
                        {currentOrder.upsell2_clicked}
                      </Box>
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateOrderCallAction({
                          _id: currentOrder._id,
                          action_name: "phone2_clicked",
                        })
                      )
                        .unwrap()
                        .then((_) => {
                          window.location.href = `tel:${currentOrder.client_phone2}`;
                        });
                    }}
                    display="inline-block"
                    marginInline={2}
                    color={theme.blue}
                  >
                    <FaPhone size={23} />
                    {currentOrder.phone2_clicked > 0 && (
                      <Box color={theme.error}>
                        {currentOrder.phone2_clicked}
                      </Box>
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateOrderCallAction({
                          _id: currentOrder._id,
                          action_name: "sms2_clicked",
                        })
                      )
                        .unwrap()
                        .then((_) => {
                          generateSmsMessage(
                            currentOrder.serial_number,
                            currentOrder.client_phone2
                          );
                        });
                    }}
                    display="inline-block"
                    marginInline={2}
                    color={theme.primary}
                  >
                    <FaSms size={23} />
                    {currentOrder.sms2_clicked > 0 && (
                      <Box color={theme.error}>{currentOrder.sms2_clicked}</Box>
                    )}
                  </Button>
                </>
              )}
            </Text>
          </Stack>
          <Stack spacing={2}>
            <Text
              fontWeight="bold"
              textTransform="capitalize"
              color={theme.dark}
              maxW="200px"
            >
              العنوان: {currentOrder?.client_address}
            </Text>
            <Text
              fontWeight="bold"
              textTransform="capitalize"
              color={theme.dark}
              maxW="200px"
            >
              الملاحظه: {currentOrder.note || "لا يوجد ملاحظه"}
            </Text>
            <Text
              fontWeight="bold"
              textTransform="capitalize"
              color={theme.dark}
            >
              محافظة الشحن:{" "}
              {
                governorates.find(
                  (gov) =>
                    +gov.id === currentOrder?.shipping_governorate?.governorate
                )?.governorate_name_ar
              }
            </Text>
            <Text
              fontWeight="bold"
              textTransform="capitalize"
              color={theme.dark}
            >
              المدينة:{" "}
              {
                cities.find((city) => +city.id === currentOrder?.city)
                  ?.city_name_ar
              }
            </Text>
          </Stack>
        </SimpleGrid>

        <Flex mb={4} fontWeight="bold" color={theme.dark}>
          {t("pages.orders.details")}
        </Flex>
        <Table textAlign="center">
          <thead>
            <tr>
              <th>#</th>
              <th>{t("pages.orders.product")}</th>
              <th>سعر القطعة الواحدة</th>
              <th>الخصم</th>
              <th>{t("pages.orders.quantity")}</th>
              <th>الخاصية</th>
              <th>إجمالي الخصم</th>
              <th>مجموع سعر القطع</th>
            </tr>
          </thead>
          <tbody>
            {currentOrder?.items?.map((el, index) => (
              <tr key={el._id}>
                <td>{index + 1}</td>
                <td>{el.product?.name}</td>
                <td>{el.sale_price}</td>
                <td>{el?.discount || 0}</td>
                <td>{el.qty}</td>
                <td>
                  {
                    el.product.properties.find(
                      (prop) => prop._id === el.property
                    )?.key
                  }
                </td>
                <td>{el?.total_discount || 0}</td>
                <td>{el.total_sale_price}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Divider mt={6} />
        <Flex marginBlock={4} fontWeight="bold" color={theme.dark}>
          {t("pages.orders.details")}
        </Flex>
        <Table textAlign="center">
          <thead>
            <tr>
              <th>إجمالي تكلفة المنتجات</th>
              <th>{t("pages.orders.shipping")}</th>
              <th>{t("pages.orders.commission")}</th>
              <th>{t("pages.orders.total")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{totalCost()}</td>
              <td>{currentOrder?.shipping}</td>
              <td>
                {auth.user.role === "moderator"
                  ? "-"
                  : currentOrder?.commission}
              </td>
              <td>{currentOrder?.total}</td>
            </tr>
          </tbody>
        </Table>
      </Box>

      {auth.user.role === "admin" && (
        <Flex mt={8} justifyContent="center">
          <Button
            disabled={disabled}
            bg={theme.blue}
            color={theme.light}
            onClick={async () => {
              try {
                const { data } = await axios.get(
                  `/api/get_next_order/${currentOrder.created_at}/${currentOrder._id}?status=${currentStatus}&query=${query}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: Cookies.get("token"),
                    },
                  }
                );
                navigate(
                  `/orders/order_details/${data.order._id}?status=${currentStatus}&query=${query}`
                );
              } catch (error) {
                console.log("something went wrong!");
                setDisabled(true);
              }
            }}
          >
            الاوردر السابق
          </Button>
          {/* <Button
            bg={theme.blue}
            color={theme.light}
            onClick={() => {
              navigate("/orders");
            }}
          >
            رجوع
          </Button> */}
        </Flex>
      )}
      <div id="notes">
        {" "}
        <OrderNotes />
      </div>

      {showUpdateOrder && (
        <UpdateModal
          data={showUpdateOrder}
          onClose={() => setShowUpdateOrder(false)}
        />
      )}
      {showUpsell && (
        <UpsellModal
          data={phoneData}
          currentOrder={currentOrder}
          onClose={() => {
            setPhoneData(null);
            setShowUpsell(false);
          }}
        />
      )}
      {showUpdateOrdersStatus && (
        <UpdateOrdersStatusModal
          data={[currentOrder]}
          onClose={() => setShowUpdateOrdersStatus(false)}
        />
      )}
      {showCancelOrdersStatus && (
        <CancelOrdersStatusModal
          data={[currentOrder]}
          onClose={() => setShowCancelOrdersStatus(false)}
        />
      )}
      {showReverseOrdersStatus && (
        <UpdateOrdersStatusModal
          data={[currentOrder]}
          reverse={true}
          onClose={() => setShowReverseOrdersStatus(false)}
        />
      )}
    </>
  );
};

export default OrderDetails;
