import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Button, Text, SimpleGrid, Alert, AlertIcon } from '@chakra-ui/react';

import Pagination from '../../shared/pagination/Pagination';
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs';
import ModeratorsTable from './ModeratorsTable';
import CreateModal from './actions/CreateModal';
import UpdateModal from './actions/UpdateModal';
import Filter from './actions/Filter';

import { getModerators } from '../../../store/moderators/moderatorsSlice';

import { ModeratorsWrapper } from './ModeratorsStyle';

import theme from '../../global/theme';
import InputSearch from "../../shared/inputSearch/InputSearch";

const Moderators = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moderators = useSelector(state => state.moderators);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const [showCreateModerator, setShowCreateModerator] = useState(false);
  const [showUpdateModerator, setShowUpdateModerator] = useState(false);

  useEffect(() => {
    dispatch(getModerators({ page, query, filter }));
  }, [dispatch, page, query, filter]);


  return (
    <ModeratorsWrapper>
      <Breadcrumbs currentPage={t('pages.moderators.moderators')} pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]} />

      {moderators.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
        <AlertIcon />
        {moderators.errors?.map((error, index) => (
          <Text key={index}>{error?.msg}</Text>
        ))}
      </Alert>}

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button bg="red.600" textTransform="uppercase"
            fontWeight="bold" fontSize={18} marginInlineEnd={4} color="white" _hover={{ bg: 'red.600' }}
            onClick={() => setShowCreateModerator(true)}
          >
            {t('general.create')}
          </Button>
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={moderators.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) => setFilter(values)}
            handleClearFilter={() => setFilter(JSON.stringify({}))}
            disablesBtns={moderators.isLoading}
          />
        </Flex>
      </SimpleGrid>

      {moderators.data?.itemsCount > 0 ? (
        <ModeratorsTable
          data={moderators.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateModerator(el)}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} boxShadow={theme.shadow} height={200} alignItems="center"
          justifyContent="center" borderRadius={16}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.moderators.no_moderators')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={moderators.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateModerator && <CreateModal onClose={() => setShowCreateModerator(false)} />}
      {showUpdateModerator && <UpdateModal data={showUpdateModerator} onClose={() => setShowUpdateModerator(false)} />}
    </ModeratorsWrapper>
  )
}

export default Moderators