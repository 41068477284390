import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Stack,
  Text,
  Button,
  Input,
  Select,
} from "@chakra-ui/react";

import FilterModal from "../../../shared/modal/FilterModal";
import Search from "../../../shared/search/Search";

import { getUsers } from "../../../../store/users/usersSlice";

import theme from "../../../global/theme";

const Filter = ({ handleFilter, handleClearFilter, disablesBtns }) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);

  watch(["target"]);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter] = useState(JSON.stringify({}));

  useEffect(() => {
    dispatch(getUsers({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <FilterModal>
      <form
        onSubmit={handleSubmit((values) => {
          let finalValues = {};
          for (let prop in values) {
            if (values[prop]) {
              if (prop === "target") {
                finalValues["target"] = values.target?._id;
              } else {
                finalValues[prop] = values[prop];
              }
            }
          }
          handleFilter(finalValues);
        })}
      >
        <Stack spacing={6}>
          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("general.from")}
            </FormLabel>
            <Input
              type="date"
              bg={theme.bg}
              color={theme.dark}
              border="none"
              borderRadius={4}
              placeholder={t("general.from")}
              _placeholder={{ textTransform: "capitalize" }}
              {...register("from")}
            />
            {errors.from?.message && (
              <Text color="red.600" marginTop={2}>
                {errors.from.message}
              </Text>
            )}
          </FormControl>

          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("general.to")}
            </FormLabel>
            <Input
              type="date"
              bg={theme.bg}
              color={theme.dark}
              border="none"
              borderRadius={4}
              placeholder={t("general.to")}
              _placeholder={{ textTransform: "capitalize" }}
              {...register("to")}
            />
            {errors.to?.message && (
              <Text color="red.600" marginTop={2}>
                {errors.to.message}
              </Text>
            )}
          </FormControl>

          {auth.user?.role === "admin" && (
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                المستخدم
              </FormLabel>
              <Search
                page={page}
                itemsCount={users.data?.itemsCount}
                data={users.data?.data}
                prop="name"
                currentElement={getValues("target")}
                handleChangePage={(page) => {
                  setPage(page);
                }}
                handleChangeQuery={(query) => {
                  setQuery(query);
                }}
                handleSelected={(item) => {
                  setValue("target", item);
                }}
              />
              {errors.target?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.target.message}
                </Text>
              )}
            </FormControl>
          )}

          {auth.user?.role === "admin" && (
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                نوع المستخدم
              </FormLabel>
              <Select bg={theme.light} color={theme.dark} {...register("type")}>
                <option value="">الجميع</option>
                <option value="vendor">تاجر</option>
                <option value="sales">مسوق</option>
              </Select>
            </FormControl>
          )}

          {auth.user?.role === "admin" && (
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                حالة الطلب
              </FormLabel>
              <Select
                bg={theme.light}
                color={theme.dark}
                {...register("status")}
              >
                <option value="">الجميع</option>
                <option value="pending">جار المراجعة</option>
                <option value="approved">تمت الموافقه</option>
              </Select>
            </FormControl>
          )}
        </Stack>

        <Button
          type="submit"
          isLoading={disablesBtns}
          mt={8}
          width="100%"
          colorScheme="blue"
        >
          {t("general.save")}
        </Button>
        <Button
          type="button"
          isLoading={disablesBtns}
          mt={2}
          width="100%"
          colorScheme="red"
          onClick={handleClearFilter}
        >
          {t("general.reset")}
        </Button>
      </form>
    </FilterModal>
  );
};

export default Filter;
