import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import { openUser } from "../../../../store/users/usersSlice";

import theme from "../../../global/theme";

const OpenModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={20} padding={4} bg={theme.dark}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(openUser(data))
                .unwrap()
                .then((_) => {
                  window.location.href = "/";
                })
                .catch((_) => {
                  onClose();
                });
            }}
          >
            <ModalHeader
              textAlign="center"
              color="orange"
              textTransform="uppercase"
              fontSize={22}
            >
              فتح الحساب
            </ModalHeader>
            <ModalBody>
              <Flex alignItems="center" justifyContent="center">
                <Text color="green.600" marginEnd={4}>
                  <FiIcons.FiCheckCircle size={24} />
                </Text>
                <Text
                  as="h3"
                  textTransform="capitalize"
                  fontWeight="semibold"
                  color="white"
                >
                  هل ترغب في فتح الحساب ؟
                </Text>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Flex justifyContent="flex-end">
                <Button
                  type="submit"
                  rightIcon={<FiIcons.FiSave />}
                  color="white"
                  bg="green"
                  paddingInline={4}
                  paddingBlock={2}
                  height="auto"
                  textTransform="capitalize"
                  isLoading={users.isLoading}
                  _hover={{ background: "green" }}
                >
                  {t("general.save")}
                </Button>
                <Button
                  type="button"
                  onClick={onClose}
                  rightIcon={<FiIcons.FiMinimize2 />}
                  color="white"
                  bg="red.600"
                  paddingInline={4}
                  paddingBlock={2}
                  height="auto"
                  textTransform="capitalize"
                  marginInlineStart={4}
                  isLoading={users.isLoading}
                  _hover={{ background: "red.600" }}
                >
                  {t("general.close")}
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OpenModal;
