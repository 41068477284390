import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

export const getRequests = createAsyncThunk(
  "requests/getRequests",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_requests?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createRequest = createAsyncThunk(
  "requests/createRequest",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_request", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateRequest = createAsyncThunk(
  "requests/updateRequest",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_request/${args.id}`,
        args.values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const deleteRequest = createAsyncThunk(
  "requests/deleteRequest",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(`/api/delete_request/${args._id}`, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const requestsSlice = createSlice({
  name: "requests",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get requests
      .addCase(getRequests.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      })

      // Create request
      .addCase(createRequest.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount = (state.data.itemsCount || 0) + 1;
        if (state.data.data?.length < 10) {
          state.data.data = [...(state.data.data || []), action.payload.data];
        }
        state.errors = [];
      })
      .addCase(createRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Update request
      .addCase(updateRequest.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.data.data?.findIndex(
          (el) => el._id === action.payload.data._id
        );
        if (index !== -1) {
          state.data.data[index] = action.payload.data;
        }
        state.errors = [];
      })
      .addCase(updateRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Delete request
      .addCase(deleteRequest.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.data = state.data.data.filter(
          (el) => el._id !== action.payload.data._id
        );
        state.errors = [];
      })
      .addCase(deleteRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default requestsSlice.reducer;
