import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";

import Table from "../../../shared/table/Table";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SuggestionsTable = ({ data, page, handleView }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          {auth.user.role === "admin" && <th>اسم المسوق</th>}
          <th>اسم المنتج المقترح</th>
          <th>سعر البيع المقترح</th>
          <th>الوصف</th>
          <th>حالة الإقتراح</th>
          <th>{t("general.action")}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{(page - 1) * 10 + (index + 1)}</td>
            {auth.user.role === "admin" && <td>{el?.user?.name}</td>}
            <td>{el.name}</td>
            <td>{el.sale_price}</td>
            <td>{el.description.slice(0, 30)}</td>
            <td>{t(`pages.requests.${el.status}`)}</td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <Button
                bg="transparent"
                color="orange"
                size="xs"
                onClick={() => handleView(el)}
              >
                <FiIcons.FiEye size={20} />
              </Button>

              {/* <Button
                bg="transparent"
                color="green"
                size="xs"
                onClick={() => handleUpdate(el)}
              >
                <FiIcons.FiEdit size={20} />
              </Button> */}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SuggestionsTable;
