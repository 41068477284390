import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Badge,
  VStack,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import {
  getPopularProducts,
  getProducts,
} from "../../../store/products/productsSlice";
import { useNavigate } from "react-router-dom";
import systemBg from "../../../assets/images/logo.png";

const MostPopularProducts = () => {
  const dispatch = useDispatch();
  const popularProducts = useSelector((state) => state.products.popular?.data);
  const recentProducts = useSelector((state) => state.products.data?.data);
  const loading = useSelector((state) => state.products.loading);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getPopularProducts({
        filter: JSON.stringify({ is_active: true, is_most_popular: true }),
      })
    );
    dispatch(
      getProducts({
        filter: JSON.stringify({ is_active: true, is_most_recent: true }),
      })
    );
  }, [dispatch]);

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const renderProductCard = (product) => (
    <Box
      key={product._id}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      p="6"
      bg="white"
      _hover={{ boxShadow: "xl", transform: "scale(1.05)" }}
      transition="all 0.3s"
      cursor="pointer"
      onClick={() => navigate(`/available_products/${product._id}`)}
    >
      <Image
        src={product.image ? `/products/${product.image}` : systemBg}
        alt={product?.name}
        objectFit="cover"
        w="100%"
        h="200px"
      />
      <VStack align="start" mt="4" spacing="3">
        <Heading size="sm">{product?.name}</Heading>
        {/* <Text color="gray.600">{product?.category.name}</Text> */}
        <Text fontWeight="bold" fontSize="lg"></Text>
        <Badge colorScheme="orange" variant="solid">
          {product?.sale_price} EGP
        </Badge>
      </VStack>
    </Box>
  );

  return (
    <Box maxW="7xl" mx="auto" px="4" py="8">
      <Heading mb="6" textAlign="center" fontSize="3xl">
        المنتجات المضافة حديثاً
      </Heading>
      <SimpleGrid columns={[2, 3, 5]} spacing="6">
        {recentProducts?.map((product) => renderProductCard(product))}
      </SimpleGrid>
      <Heading my="6" textAlign="center" fontSize="3xl">
        المنتجات الأكثر مبيعاً
      </Heading>
      <SimpleGrid columns={[2, 3, 5]} spacing="6" mb="8">
        {popularProducts?.map((product) => renderProductCard(product))}
      </SimpleGrid>
    </Box>
  );
};

export default MostPopularProducts;
