import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialState = {
  isLoading: false,
  data: {},
  user: null,
  errors: [],
};

// Thunks
export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_users?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_user", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(`/api/update_user/${args._id}`, args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const openUser = createAsyncThunk(
  "users/openUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `/api/open_user/${args._id}`,
        {},
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "users/updateUserPassword",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_user_password/${args.id}`,
        args.values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

// Slice
const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get users
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors || [];
      })

      // Create user
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount = (state.data.itemsCount || 0) + 1;
        if (state.data?.data?.length < 10) {
          state.data.data = [...(state.data.data || []), action.payload.data];
        }
        state.errors = [];
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Update user
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.data.data?.findIndex(
          (el) => el._id === action.payload.data._id
        );
        if (index !== -1) {
          state.data.data[index] = action.payload.data;
        }
        state.errors = [];
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Open user
      .addCase(openUser.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(openUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errors = [];
        Cookies.set("token", action.payload.token, {
          expires: 1, // 1 day
        });
      })
      .addCase(openUser.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Update user password
      .addCase(updateUserPassword.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      });
  },
});

export default usersSlice.reducer;
