export const validateMaxLength = (field, length) => {
  if (String(field)?.trim()?.length > length) {
    return false;
  }
  return true;
}

export const validateMinLength = (field, length) => {
  if (String(field)?.trim()?.length < length) {
    return false;
  }
  return true;
}

export const validateNumeric = (field) => {
  if (isNaN(field)) {
    return false;
  }
  return true;
}