import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  SimpleGrid,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlacklist,
  createBlacklist,
  updateBlacklist,
  deleteBlacklist,
  getPossibleBlacklist,
} from "./../../../store/blacklist/blacklistSlice"; // import the slice actions
import Pagination from "../../shared/pagination/Pagination";
const Blacklist = () => {
  const dispatch = useDispatch();
  const { isLoading, data, possible, errors } = useSelector(
    (state) => state.blacklist
  );
  const toast = useToast();

  // Manage form state for adding and editing
  const [formState, setFormState] = useState({ phone: "" });
  const [editingId, setEditingId] = useState(null);
  const [page, setPage] = useState(1);

  // Chakra UI modal control for adding/editing blacklist
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getBlacklist({ page }));
    dispatch(getPossibleBlacklist({}));
    // Fetch blacklist on mount
  }, [dispatch, page]);

  useEffect(() => {
    if (errors.length) {
      toast({
        title: "Error",
        description: "حدث خطأ في البيانات",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [errors, toast]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission for creating or updating
  const handleSubmit = () => {
    if (editingId) {
      dispatch(updateBlacklist({ _id: editingId, values: formState }));
      dispatch(getBlacklist({ page }));
      dispatch(getPossibleBlacklist({}));
      setEditingId(null);
    } else {
      dispatch(createBlacklist(formState));
      dispatch(getBlacklist({ page }));
      dispatch(getPossibleBlacklist({}));
      setEditingId(null);
    }
    onClose();
    setFormState({ phone: "" });
  };

  // Handle editing an entry
  const handleEdit = (blacklist) => {
    setFormState({ phone: blacklist.phone });
    setEditingId(blacklist._id);
    onOpen();
  };

  // Handle deleting an entry
  const handleDelete = (id) => {
    dispatch(deleteBlacklist({ _id: id }));
    dispatch(getBlacklist({ page }));
    dispatch(getPossibleBlacklist({}));
  };

  return (
    <Box p={4}>
      <Heading mb={4}>قائمة الأرقام المحظورة</Heading>

      {/* Add new entry */}
      <Button onClick={onOpen} colorScheme="blue" mb={4}>
        إضافة رقم هاتف جديد
      </Button>

      {/* Blacklist Table */}
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SimpleGrid
            columns={{ sm: 1, lg: 2 }}
            alignItems={"baseline"}
            spacing={4}
          >
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>رقم الهاتف</Th>
                  <Th>تفاصيل</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.data?.data?.map((blacklist) => (
                  <Tr key={blacklist._id}>
                    <Td>{blacklist.phone}</Td>
                    <Td>
                      <Button
                        size="sm"
                        colorScheme="yellow"
                        ml={2}
                        onClick={() => handleEdit(blacklist)}
                      >
                        تعديل
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(blacklist._id)}
                      >
                        حذف
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Table variant="compact">
              <Thead>
                <Tr>
                  <Th>رقم الهاتف</Th>
                  <Th>عدد الطلبات المرتجعة</Th>
                  <Th>موجود في البلاك ليست</Th>
                </Tr>
              </Thead>
              <Tbody>
                {possible?.data
                  ?.filter((blacklist) => blacklist.phone !== "")
                  ?.map((blacklist) => (
                    <Tr key={blacklist._id}>
                      <Td>{blacklist.phone}</Td>
                      <Td>{blacklist.returned1_count}</Td>
                      <Td>{blacklist.is_blacklisted ? "نعم" : "لا"}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </SimpleGrid>
        </>
      )}

      {/* Modal for Add/Edit Blacklist */}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setFormState({ phone: "" });
          setEditingId(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mx={10}>{editingId ? "تعديل" : "إضافة"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>رقم الهاتف</FormLabel>
              <Input
                name="phone"
                maxLength={11}
                value={formState.phone}
                onChange={handleInputChange}
                placeholder="ادخل رقم الهاتف"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit}>
              {editingId ? "تعديل" : "إضافة"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Pagination
        page={page}
        itemsCount={data.data?.itemsCount ?? 0}
        onChange={(page) => setPage(page)}
      />
    </Box>
  );
};

export default Blacklist;
