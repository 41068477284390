import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Link as ChakraLink,
  Text,
  Image,
  Divider,
  useToast,
  InputGroup,
  InputRightElement,
  Alert,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import theme from "../../global/theme";

import { login } from "../../../store/auth/authSlice";

import { AuthWarpper } from "./AuthStyle";

import brandImage from "../../../assets/images/logo.png";

import authBg from "../../../assets/images/auth-bg.jpg";

const Login = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 2000, status: "error" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const whatsappNumber = "201090371071";

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  watch("email");
  const message = encodeURIComponent(
    `${getValues("email")} مرحبا، أحتاج إلى تفعيل حسابي، برجاء المساعدة.`
  );
  return (
    <AuthWarpper>
      <Flex
        background={`url(${authBg}) no-repeat`}
        backgroundSize="cover"
        minH="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Box bg={theme.dark} p={8} className="auth-box" borderRadius={10}>
          <Box textAlign="center">
            <Image m="auto" h="80px" src={brandImage} mb={4} />

            <Heading
              color={theme.light}
              fontSize={28}
              textTransform="capitalize"
              mb={4}
            >
              {t("pages.auth.welcome_1")}
            </Heading>

            <Heading
              color={theme.text}
              fontSize={14}
              textTransform="capitalize"
            >
              {t("pages.auth.welcome_2")}
            </Heading>
          </Box>

          <Divider borderColor={theme.border} marginBlock={6} />
          {error === "auth.user_is_not_active" && (
            <>
              <Alert
                color={theme.error}
                display="flex"
                alignItems="center"
                flexDirection="column"
                bg="red.50"
                borderLeft="4px solid"
                borderColor="red.400"
                p={4}
                mb={4}
              >
                <Text
                  fontWeight="bold"
                  color="red.600"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FiIcons.FiAlertTriangle
                    style={{ marginRight: "8px", color: "red.600" }}
                  />
                  برجاء التواصل مع الدعم للتنشيط{" "}
                </Text>

                <ChakraLink
                  href={`https://wa.me/${whatsappNumber}?text=${message}`}
                  ml={2}
                  display="flex"
                  alignItems="center"
                  color="green.600"
                  fontWeight="bold"
                  textDecoration="underline"
                  _hover={{ textDecoration: "underline", color: "green.800" }}
                  isExternal
                >
                  <FiIcons.FiPhone
                    style={{ marginRight: "4px", color: "green.600" }}
                  />
                  01090371071
                </ChakraLink>
                <ChakraLink
                  as={Link}
                  isExternal
                  to="https://www.facebook.com/profile.php?id=100083218244241&mibextid=ZbWKwL"
                  ml={2}
                  display="flex"
                  alignItems="center"
                  color="yellow.800"
                  fontWeight="bold"
                  _hover={{ textDecoration: "underline" }}
                >
                  <FiIcons.FiExternalLink
                    style={{ marginRight: "4px", color: "yellow.800" }}
                  />
                  الدعم
                </ChakraLink>
              </Alert>
            </>
          )}
          <form
            onSubmit={handleSubmit((values) => {
              dispatch(login(values))
                .unwrap()
                .then((_) => {
                  navigate("/");
                })
                .catch((error) => {
                  console.log(error);
                  setError(error?.errors[0]?.msg);
                  if (error?.errors[0]?.msg === "auth.invalid_credentials") {
                    toast({ description: "يرجي التأكد من كلمة المرور" });
                  } else {
                    toast({
                      description: "برجاء التواصل مع الدعم للتنشيط 01090371071",
                    });
                  }
                });
            })}
          >
            <Stack spacing={4}>
              <FormControl>
                <FormLabel color={theme.light} textTransform="capitalize">
                  {t("pages.auth.email")}
                </FormLabel>
                <Input
                  type="text"
                  placeholder={t("pages.auth.email")}
                  border="none"
                  color={theme.dark}
                  bg={theme.bg}
                  _placeholder={{ color: theme.text }}
                  paddingInline={4}
                  {...register("email", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.email?.message && (
                  <Text color={theme.error}>{errors.email?.message}</Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel color={theme.light} textTransform="capitalize">
                  {t("pages.auth.password")}
                </FormLabel>
                <InputGroup>
                  <Input
                    type={isPasswordShown ? "text" : "password"}
                    placeholder={t("pages.auth.password")}
                    border="none"
                    color={theme.dark}
                    bg={theme.bg}
                    autoComplete="false"
                    _placeholder={{ color: theme.text }}
                    paddingInline={4}
                    {...register("password", {
                      required: `${t("validation.required")}`,
                      minLength: {
                        value: 8,
                        message: `${t("validation.min_length")} 8`,
                      },
                    })}
                  />
                  <InputRightElement>
                    <Button
                      p={0}
                      bg="none"
                      color={theme.dark}
                      _hover={{ bg: "none" }}
                      type="button"
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                    >
                      {isPasswordShown ? (
                        <FiIcons.FiEyeOff />
                      ) : (
                        <FiIcons.FiEye />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password?.message && (
                  <Text color={theme.error}>{errors.password?.message}</Text>
                )}
              </FormControl>

              <ChakraLink
                as={Link}
                to="/reset_password"
                textTransform="capitalize"
              >
                <Flex>
                  <Text color={theme.light}>
                    {t("pages.auth.forget_password")}
                  </Text>
                  <Text ms={2} color={theme.blue}>
                    {t("pages.auth.reset")}
                  </Text>
                </Flex>
              </ChakraLink>

              <Button
                type="submit"
                textTransform="capitalize"
                bg={theme.blue}
                color={theme.light}
                _hover={{ bg: theme.blue }}
                isLoading={auth.isLoading}
              >
                {t("pages.auth.login")}
              </Button>

              <ChakraLink as={Link} to="/register" textTransform="capitalize">
                <Flex>
                  <Text color={theme.light}>
                    {t("pages.auth.donot_have_account")}
                  </Text>
                  <Text ms={2} color={theme.blue}>
                    {t("pages.auth.register")}
                  </Text>
                </Flex>
              </ChakraLink>
            </Stack>
          </form>
        </Box>
      </Flex>
    </AuthWarpper>
  );
};

export default Login;
