import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Initial state
const initialState = {
  isLoading: false,
  data: [],
  product: null,
  errors: [],
};

export const addProductToEasyOrder = createAsyncThunk(
  "products/addProductToEasyOrder",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `/api/easy-order-add_product/${args.id}`,
        {
          commission: args.commission,
        },
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const sendShippingCostsToEasyOrder = createAsyncThunk(
  "products/sendShippingCosts",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.patch(
        `/api/easy-order-shipping`,
        {},
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const esayOrderSlice = createSlice({
  name: "esayOrde",
  initialState,
  extraReducers: (builder) => {
    builder

      // Handle addProductToEasyOrder
      .addCase(addProductToEasyOrder.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(addProductToEasyOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.data = action.payload.data; // Assuming response contains updated data
        state.errors = [];
      })
      .addCase(addProductToEasyOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })
      // Handle sendShippingCostsToEasyOrder
      .addCase(sendShippingCostsToEasyOrder.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(sendShippingCostsToEasyOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.data = action.payload.data; // Assuming response contains updated data
        state.errors = [];
      })
      .addCase(sendShippingCostsToEasyOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default esayOrderSlice.reducer;
