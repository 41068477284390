import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";

// Register necessary components for Chart.js
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const PieChart = ({ statuses, t }) => {
  // Prepare data for the Pie Chart
  const chartData = {
    labels: statuses.map((item) =>
      t(
        `pages.${item.category === "requestsActions" ? "requests" : "orders"}.${
          item.status
        }`
      )
    ),
    datasets: [
      {
        label: "عدد الاجراءات",
        data: statuses.map((item) => item.count),
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ width: "200px", height: "200px" }}>
      <Pie data={chartData} width={200} height={200} />
    </div>
  );
};

export default PieChart;
