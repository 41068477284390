import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

export const getShippingCompanies = createAsyncThunk(
  "shippingCompanies/getShippingCompanies",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const response = await axios.get(
        `/api/get_shipping_companies?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createShippingCompany = createAsyncThunk(
  "shippingCompanies/createShippingCompany",
  async (args, thunkApi) => {
    try {
      const response = await axios.post("/api/create_shipping_company", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateShippingCompany = createAsyncThunk(
  "shippingCompanies/updateShippingCompany",
  async (args, thunkApi) => {
    try {
      const response = await axios.put(
        `/api/update_shipping_company/${args.id}`,
        args.values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const deleteShippingCompany = createAsyncThunk(
  "shippingCompanies/deleteShippingCompany",
  async (args, thunkApi) => {
    try {
      const response = await axios.delete(
        `/api/delete_shipping_company/${args._id}`,
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const shippingCompaniesSlice = createSlice({
  name: "shippingCompanies",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get shipping companies
      .addCase(getShippingCompanies.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getShippingCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getShippingCompanies.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors || [];
      })

      // Create shipping company
      .addCase(createShippingCompany.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createShippingCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount = (state.data.itemsCount || 0) + 1;
        if (state.data.data?.length < 10) {
          state.data.data = [...(state.data.data || []), action.payload];
        }
        state.errors = [];
      })
      .addCase(createShippingCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Update shipping company
      .addCase(updateShippingCompany.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateShippingCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.data.data?.findIndex(
          (el) => el._id === action.payload._id
        );
        if (index !== -1) {
          state.data.data[index] = action.payload;
        }
        state.errors = [];
      })
      .addCase(updateShippingCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Delete shipping company
      .addCase(deleteShippingCompany.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteShippingCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.data =
          state.data.data?.filter((el) => el._id !== action.payload._id) || [];
        state.errors = [];
      })
      .addCase(deleteShippingCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      });
  },
});

export default shippingCompaniesSlice.reducer;
