import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import * as FiIcons from 'react-icons/fi';

import Table from '../../shared/table/Table';
import { useNavigate } from 'react-router-dom';

const ProductsTable = ({ data, page, handleUpdate, handleUpdateAccess }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getTotalQuantity = (item) => {
    let total = 0;
    for (let i = 0; i < item.properties.length; i++) {
      total += item.properties[i]?.value;
    }
    return total
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>{t('pages.products.name')}</th>
          <th>{t('pages.products.barcode')}</th>
          <th>{t('pages.products.category')}</th>
          <th>{t('pages.products.purchase_price')}</th>
          <th>{t('pages.products.sale_price')}</th>
          <th>{t('pages.products.quantity')}</th>
          <th>نسبة التسليم</th>
          <th>نشط ؟</th>
          <th>{t('general.action')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{((page - 1) * 10) + (index + 1)}</td>
            <td>{el.name}</td>
            <td>{el.barcode}</td>
            <td>{el.category?.name}</td>
            <td>{el.purchase_price}</td>
            <td>{el.sale_price}</td>
            <td>{getTotalQuantity(el)}</td>
            <td>{`${el.percent?.toFixed(2) ?? 0} %`}</td>
            <td>{el.is_active ? "نشط" : "غير نشط"}</td>
            <td style={{ display: "flex" }}>
              <Button
                bg="transparent" color="orange" size="xs"
                onClick={() => navigate(`/available_products/${el._id}`)}
              >
                <FiIcons.FiEye size={20} />
              </Button>

              <Button
                bg="transparent" color="green" size="xs"
                onClick={() => handleUpdate(el)}
              >
                <FiIcons.FiEdit size={20} />
              </Button>

              <Button
                bg="transparent" color="green" size="xs"
                onClick={() => handleUpdateAccess(el)}
              >
                <FiIcons.FiUnlock size={20} />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ProductsTable