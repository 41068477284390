import React from "react";

import {
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  Select,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import theme from "../../../global/theme";
import { useTranslation } from "react-i18next";
const ReverseOrderStatusModal = ({
  isItemsStatusDiff,
  register,
  onClose,
  currentStatus,
  auth,
  errors,
  getValues,
  orders,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader
        textAlign="center"
        color="orange"
        textTransform="uppercase"
        fontSize={22}
      >
        إرجاع حالات الطلبات
      </ModalHeader>
      <ModalBody>
        {isItemsStatusDiff() ? (
          <Box color={theme.light} textAlign="center">
            حالات الطلب غير موحده
          </Box>
        ) : (
          <>
            <Select
              bg={theme.bg}
              color={theme.dark}
              {...register("status", {
                required: `${t("validation.required")}`,
              })}
            >
              {auth.user.role === "admin" &&
                (currentStatus === "preparing" ? (
                  <>
                    <option style={{ color: theme.dark }} value="preparing">
                      إرجاع قبل تحضير للشحن
                    </option>
                  </>
                ) : currentStatus === "cancelled" ? (
                  <>
                    <option style={{ color: theme.dark }} value="cancelled">
                      إرجاع قبل معلومات غير صحيحة
                    </option>
                  </>
                ) : currentStatus === "declined1" ? (
                  <>
                    <option style={{ color: theme.dark }} value="declined1">
                      إرجاع قبل ملغي
                    </option>
                  </>
                ) : currentStatus === "returned1" ? (
                  <>
                    <option style={{ color: theme.dark }} value="returned1">
                      إرجاع قبل مرتجع
                    </option>
                  </>
                ) : currentStatus === "declined2" ? (
                  <>
                    <option style={{ color: theme.dark }} value="declined2">
                      إرجاع قبل ملغي
                    </option>
                  </>
                ) : currentStatus === "returned2" ? (
                  <>
                    <option style={{ color: theme.dark }} value="returned2">
                      إرجاع قبل مرتجع
                    </option>
                  </>
                ) : currentStatus === "shipped" ? (
                  <>
                    <option style={{ color: theme.dark }} value="shipped">
                      إرجاع قبل في الشحن
                    </option>
                    <option style={{ color: theme.dark }} value="skip"></option>
                  </>
                ) : currentStatus === "skip" ? (
                  <>
                    <option style={{ color: theme.dark }} value="skip">
                      إرجاع جار الاسترجاع
                    </option>
                  </>
                ) : currentStatus === "available" ? (
                  <>
                    <option style={{ color: theme.dark }} value="collected">
                      إرجاع قبل تم التسليم
                    </option>
                  </>
                ) : currentStatus === "ask_to_return" ? (
                  <>
                    <option style={{ color: theme.dark }} value="ask_to_return">
                      إرجاع قبل طلب العميل الاسترجاع
                    </option>
                  </>
                ) : currentStatus === "collected" ? (
                  <option style={{ color: theme.dark }} value="collected">
                    إرجاع قبل تم االتحصيل
                  </option>
                ) : currentStatus === "holding" ? (
                  <option style={{ color: theme.dark }} value="collected">
                    إرجاع قبل مؤجل
                  </option>
                ) : (
                  <option style={{ color: theme.dark }} value="">
                    لا يوجد حالات اخرى
                  </option>
                ))}
            </Select>
            {errors.status?.message && (
              <Text color="red.600" marginTop={2}>
                {errors.status.message}
              </Text>
            )}

            {getValues("status") === "holding" && (
              <>
                <Input
                  mt={4}
                  color={theme.light}
                  colorScheme={theme.dark}
                  type="datetime-local"
                  {...register("holding_to", {
                    required: {
                      value: true,
                      message: `${t("validation.required")}`,
                    },
                  })}
                />
                {errors.holding_to?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.holding_to.message}
                  </Text>
                )}
              </>
            )}
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            rightIcon={<FiIcons.FiSave />}
            color="white"
            bg="green"
            paddingInline={4}
            paddingBlock={2}
            height="auto"
            textTransform="capitalize"
            isLoading={orders.isLoading}
            _hover={{ background: "green" }}
          >
            {t("general.save")}
          </Button>
          <Button
            type="button"
            onClick={onClose}
            rightIcon={<FiIcons.FiMinimize2 />}
            color="white"
            bg="red.600"
            paddingInline={4}
            paddingBlock={2}
            height="auto"
            textTransform="capitalize"
            marginInlineStart={4}
            isLoading={orders.isLoading}
            _hover={{ background: "red.600" }}
          >
            {t("general.close")}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};

export default ReverseOrderStatusModal;
