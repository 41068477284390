import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FcStatistics } from "react-icons/fc";
import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  Heading,
  Text,
  Image,
  CircularProgress,
  Accordion,
  AccordionItem,
  Link as ChakraLink,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";
import * as AiIcons from "react-icons/ai";
import { MdAppBlocking } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import {
  MdFavoriteBorder,
  MdAddShoppingCart,
  MdOutlineWbIncandescent,
} from "react-icons/md";
import { TfiAnnouncement } from "react-icons/tfi";
import { FaLink } from "react-icons/fa";
import { toggleSidebar } from "../../../store/sidebar/sidebarSlice";

import systemBg from "../../../assets/images/logo.png";

import theme from "../../global/theme";

import { SidebarOverlay, SidebarWrapper } from "./SidebarStyle";
import { MdRepeatOneOn } from "react-icons/md";
const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sidebar = useSelector((state) => state.sidebar);
  const auth = useSelector((state) => state.auth);

  const closeSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      {sidebar.isNotOpened && (
        <SidebarOverlay
          as={Box}
          className="sidebar-overlay"
          position="fixed"
          top="0"
          right="0"
          w="100%"
          h="100vh"
          bg="rgba(0, 0, 0, .5)"
          zIndex="99"
          role="button"
          onClick={() => dispatch(toggleSidebar())}
        ></SidebarOverlay>
      )}
      <SidebarWrapper className={sidebar.isNotOpened ? "active" : ""}>
        {auth.isLoading ? (
          <Flex h="100vh" alignItems="center" justifyContent="center">
            <CircularProgress isIndeterminate color={theme.text} />
          </Flex>
        ) : (
          <>
            <Flex alignItems="center" mb={8}>
              <Image w="60px" src={systemBg} />
              <Heading
                //
                className="brand-text"
                textTransform="uppercase"
                color="white"
                size="lg"
              >
                SAFKA
              </Heading>
            </Flex>

            {/* <Text
              textTransform="capitalize"
              mb={4}
              className="text"
              fontSize={16}
              color="#5e76a1"
              fontWeight="600"
              mt={6}
            >
              {t("layout.sidebar.profile")}
            </Text>

            <ChakraLink as={Link} to="profile" className="sidebar-profile">
              <Flex alignItems="center" mb={8}>
                <Avatar size="sm" name={auth.user?.name}>
                  <AvatarBadge
                    borderColor="transparent"
                    boxSize="10px"
                    bg="green.500"
                  />
                </Avatar>
                <Box>
                  <Text
                    marginInline={3}
                    textTransform="capitalize"
                    fontSize={14}
                    fontWeight="600"
                    color="white"
                  >
                    {auth.user?.name && auth.user.name.slice(0, 10)}
                  </Text>
                  <Text
                    marginInline={3}
                    textTransform="capitalize"
                    fontSize={12}
                    color="#5e76a1"
                    fontWeight="600"
                  >
                    {auth.user.role}
                  </Text>
                </Box>
              </Flex>
            </ChakraLink>
            <Text
              textTransform="capitalize"
              mb={4}
              className="text"
              fontSize={16}
              color="#5e76a1"
              fontWeight="600"
            >
              {t("layout.sidebar.navigation")}
            </Text> */}

            <Box className="sidebar-links">
              <Accordion allowToggle border="none">
                <AccordionItem border="none">
                  <NavLink
                    to="/"
                    end
                    onClick={closeSidebar}
                    className="chakra-accordion__button"
                  >
                    <Flex alignItems="center">
                      <span className="icon">
                        <AiIcons.AiTwotoneHome />
                      </span>
                      <span className="text">
                        {t("layout.sidebar.dashboard")}
                      </span>
                    </Flex>
                  </NavLink>
                </AccordionItem>

                {((auth.user.role === "admin" &&
                  auth.user?.rule?.permissions?.includes("manage_settings")) ||
                  auth.user?.email == "shimaabayoumi182@gmail.com" ||
                  auth.user?.email == "ma5zn@gmail.com") && (
                  <AccordionItem>
                    <Heading
                      className="brand-text"
                      textTransform="uppercase"
                      color="white"
                      size="lg"
                    >
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="start">
                          الأدمن
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </Heading>
                    <AccordionPanel pb={4}>
                      {auth.user.role === "admin" &&
                        (auth.user?.email == "ma5zn@gmail.com" ||
                          auth.user?.rule?.permissions?.includes(
                            "manage_settings"
                          )) && (
                          <>
                            <AccordionItem border="none">
                              <NavLink
                                to="/statistics"
                                end
                                onClick={closeSidebar}
                                className="chakra-accordion__button"
                              >
                                <Flex alignItems="center">
                                  <span className="icon">
                                    <FcStatistics />
                                  </span>
                                  <span className="text">الإحصائيات</span>
                                </Flex>
                              </NavLink>
                            </AccordionItem>
                          </>
                        )}
                      {((auth.user.role === "admin" &&
                        auth.user?.rule?.permissions?.includes(
                          "manage_settings"
                        )) ||
                        auth.user?.email == "shimaabayoumi182@gmail.com") && (
                        <>
                          <AccordionItem border="none">
                            <NavLink
                              to="/announcements"
                              end
                              onClick={closeSidebar}
                              className="chakra-accordion__button"
                            >
                              <Flex alignItems="center">
                                <span className="icon">
                                  <TfiAnnouncement />
                                </span>
                                <span className="text">التنبيهات</span>
                              </Flex>
                            </NavLink>
                          </AccordionItem>
                        </>
                      )}
                      {auth.user.role === "admin" &&
                        auth.user?.rule?.permissions?.includes(
                          "manage_settings"
                        ) && (
                          <>
                            <AccordionItem border="none">
                              <NavLink
                                to="rules"
                                onClick={closeSidebar}
                                className="chakra-accordion__button"
                              >
                                <Flex alignItems="center">
                                  <span className="icon">
                                    <FiIcons.FiDisc />
                                  </span>
                                  <span className="text">
                                    {t("layout.sidebar.rules")}
                                  </span>
                                </Flex>
                              </NavLink>
                            </AccordionItem>

                            <AccordionItem border="none">
                              <NavLink
                                to="categories"
                                onClick={closeSidebar}
                                className="chakra-accordion__button"
                              >
                                <Flex alignItems="center">
                                  <span className="icon">
                                    <FiIcons.FiAperture />
                                  </span>
                                  <span className="text">
                                    {t("layout.sidebar.categories")}
                                  </span>
                                </Flex>
                              </NavLink>
                            </AccordionItem>
                          </>
                        )}
                    </AccordionPanel>
                  </AccordionItem>
                )}
                {auth.user.role === "admin" && (
                  <AccordionItem>
                    <Heading
                      className="brand-text"
                      textTransform="uppercase"
                      color="white"
                      size="lg"
                    >
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="start">
                          شركات الشحن
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </Heading>
                    <AccordionPanel pb={4}>
                      <AccordionItem border="none">
                        <NavLink
                          to="price_list"
                          onClick={closeSidebar}
                          className="chakra-accordion__button"
                        >
                          <Flex alignItems="center">
                            <span className="icon">
                              <FiIcons.FiDollarSign />
                            </span>
                            <span className="text">
                              {t("layout.sidebar.price_list")}
                            </span>
                          </Flex>
                        </NavLink>
                      </AccordionItem>
                      {auth.user?.role === "admin" && (
                        <AccordionItem border="none">
                          <NavLink
                            to="blacklist"
                            onClick={closeSidebar}
                            className="chakra-accordion__button"
                          >
                            <Flex alignItems="center">
                              <span className="icon">
                                <MdAppBlocking />
                              </span>
                              <span className="text">الأرقام المحظورة</span>
                            </Flex>
                          </NavLink>
                        </AccordionItem>
                      )}

                      {auth.user.role === "admin" &&
                        auth.user?.rule?.permissions?.includes(
                          "manage_warehouses"
                        ) && (
                          <AccordionItem border="none">
                            <NavLink
                              to="warehouses"
                              onClick={closeSidebar}
                              className="chakra-accordion__button"
                            >
                              <Flex alignItems="center">
                                <span className="icon">
                                  <FiIcons.FiLayers />
                                </span>
                                <span className="text">
                                  {t("layout.sidebar.warehouses")}
                                </span>
                              </Flex>
                            </NavLink>
                          </AccordionItem>
                        )}
                      {auth.user.role === "admin" &&
                        auth.user?.rule?.permissions?.includes(
                          "manage_settings"
                        ) && (
                          <AccordionItem border="none">
                            <NavLink
                              to="shipping_companies"
                              onClick={closeSidebar}
                              className="chakra-accordion__button"
                            >
                              <Flex alignItems="center">
                                <span className="icon">
                                  <FiIcons.FiTruck />
                                </span>
                                <span className="text">
                                  {t("layout.sidebar.shipping_companies")}
                                </span>
                              </Flex>
                            </NavLink>
                          </AccordionItem>
                        )}
                    </AccordionPanel>
                  </AccordionItem>
                )}
                <AccordionItem>
                  <Heading
                    className="brand-text"
                    textTransform="uppercase"
                    color="white"
                    size="lg"
                  >
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="start">
                        المنتجات
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel pb={4}>
                    {auth.user.role === "admin" &&
                      auth.user?.rule?.permissions?.includes(
                        "manage_products"
                      ) && (
                        <>
                          <AccordionItem border="none">
                            <NavLink
                              to="products"
                              onClick={closeSidebar}
                              className="chakra-accordion__button"
                            >
                              <Flex alignItems="center">
                                <span className="icon">
                                  <FiIcons.FiShoppingCart />
                                </span>
                                <span className="text">
                                  {t("layout.sidebar.products")}
                                </span>
                              </Flex>
                            </NavLink>
                          </AccordionItem>
                          <AccordionItem border="none">
                            <NavLink
                              to="order_products"
                              onClick={closeSidebar}
                              className="chakra-accordion__button"
                            >
                              <Flex alignItems="center">
                                <span className="icon">
                                  <BiSort />
                                </span>
                                <span className="text">ترتيب المنتجات</span>
                              </Flex>
                            </NavLink>
                          </AccordionItem>
                        </>
                      )}
                    <AccordionItem border="none">
                      <NavLink
                        to="available_products"
                        onClick={closeSidebar}
                        className="chakra-accordion__button"
                      >
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiShoppingCart />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.available_products")}
                          </span>
                        </Flex>
                      </NavLink>
                    </AccordionItem>
                    <AccordionItem border="none">
                      <NavLink
                        to="favorite_products"
                        onClick={closeSidebar}
                        className="chakra-accordion__button"
                      >
                        <Flex alignItems="center">
                          <span className="icon">
                            <MdFavoriteBorder />
                          </span>
                          <span className="text">المنتجات المفضلة</span>
                        </Flex>
                      </NavLink>
                    </AccordionItem>

                    {auth.user.role !== "merchant" && (
                      <AccordionItem border="none">
                        <NavLink
                          to="suggested_products"
                          onClick={closeSidebar}
                          className="chakra-accordion__button"
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Flex alignItems="center">
                              <span className="icon">
                                <MdOutlineWbIncandescent />
                              </span>
                              <span className="text">
                                {auth.user.role === "admin"
                                  ? "المقترحات"
                                  : "إقتراح منتج"}
                                {"  "}
                              </span>
                            </Flex>
                          </Flex>
                        </NavLink>
                      </AccordionItem>
                    )}
                    {auth.user.role === "merchant" && (
                      <>
                        <AccordionItem border="none">
                          <NavLink
                            to="my_products"
                            onClick={closeSidebar}
                            className="chakra-accordion__button"
                          >
                            <Flex alignItems="center">
                              <span className="icon">
                                <FiIcons.FiShoppingBag />
                              </span>
                              <span className="text">
                                {t("layout.sidebar.my_products")}
                              </span>
                            </Flex>
                          </NavLink>
                        </AccordionItem>
                      </>
                    )}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <Heading
                    className="brand-text"
                    textTransform="uppercase"
                    color="white"
                    size="lg"
                  >
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="start">
                        إدارة الطلبات
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel pb={4}>
                    {auth.user.role !== "merchant" && (
                      <AccordionItem border="none">
                        <NavLink
                          to="orders?open_modal=true"
                          onClick={closeSidebar}
                          className="chakra-accordion__button"
                        >
                          <Flex alignItems="center">
                            <span className="icon">
                              <MdAddShoppingCart />
                            </span>
                            <span className="text">
                              {t("layout.sidebar.create_order")}
                            </span>
                          </Flex>
                        </NavLink>
                      </AccordionItem>
                    )}
                    <AccordionItem border="none">
                      <NavLink
                        to="orders?page=1&size=20&query="
                        onClick={closeSidebar}
                        className="chakra-accordion__button"
                      >
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiShoppingBag />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.orders")}
                          </span>
                        </Flex>
                      </NavLink>
                    </AccordionItem>
                    {(auth.user.role === "marketer" ||
                      auth.user.role === "moderator") && (
                      <AccordionItem border="none">
                        <NavLink
                          to="excel_orders"
                          onClick={closeSidebar}
                          className="chakra-accordion__button"
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Flex alignItems="center">
                              <span className="icon">
                                <MdAddShoppingCart />
                              </span>
                              <span className="text">
                                {t("layout.sidebar.excel_orders")}
                              </span>
                            </Flex>
                          </Flex>
                        </NavLink>
                      </AccordionItem>
                    )}
                    {auth.user.role === "admin" && (
                      <AccordionItem border="none">
                        <NavLink
                          to="duplicate_orders"
                          onClick={closeSidebar}
                          className="chakra-accordion__button"
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Flex alignItems="center">
                              <span className="icon">
                                <MdRepeatOneOn />
                              </span>
                              <span className="text">طلبات مكررة</span>
                            </Flex>
                          </Flex>
                        </NavLink>
                      </AccordionItem>
                    )}
                  </AccordionPanel>
                </AccordionItem>
                {auth.user.role === "admin" &&
                  (auth.user?.rule?.permissions?.includes("manage_users") ||
                    auth.user?.rule?.permissions?.includes(
                      "manage_accounts"
                    )) && (
                    <AccordionItem>
                      <Heading
                        className="brand-text"
                        textTransform="uppercase"
                        color="white"
                        size="lg"
                      >
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="start">
                            المستخدمين
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </Heading>
                      <AccordionPanel pb={4}>
                        {auth.user.role === "admin" &&
                          auth.user?.rule?.permissions?.includes(
                            "manage_users"
                          ) && (
                            <AccordionItem border="none">
                              <NavLink
                                to="users"
                                onClick={closeSidebar}
                                className="chakra-accordion__button"
                              >
                                <Flex alignItems="center">
                                  <span className="icon">
                                    <FiIcons.FiUsers />
                                  </span>
                                  <span className="text">
                                    {t("layout.sidebar.users")}
                                  </span>
                                </Flex>
                              </NavLink>
                            </AccordionItem>
                          )}
                        {auth.user.role === "admin" &&
                          auth.user?.rule?.permissions?.includes(
                            "manage_accounts"
                          ) && (
                            <AccordionItem border="none">
                              <NavLink
                                to="marketers_accounts"
                                onClick={closeSidebar}
                                className="chakra-accordion__button"
                              >
                                <Flex alignItems="center">
                                  <span className="icon">
                                    <FiIcons.FiDollarSign />
                                  </span>
                                  <span className="text">
                                    {t("layout.sidebar.marketers_accounts")}
                                  </span>
                                </Flex>
                              </NavLink>
                            </AccordionItem>
                          )}
                        {auth.user.role === "admin" &&
                          auth.user?.rule?.permissions?.includes(
                            "manage_accounts"
                          ) && (
                            <AccordionItem border="none">
                              <NavLink
                                to="merchants_accounts"
                                onClick={closeSidebar}
                                className="chakra-accordion__button"
                              >
                                <Flex alignItems="center">
                                  <span className="icon">
                                    <FiIcons.FiDollarSign />
                                  </span>
                                  <span className="text">
                                    {t("layout.sidebar.merchants_accounts")}
                                  </span>
                                </Flex>
                              </NavLink>
                            </AccordionItem>
                          )}
                      </AccordionPanel>
                    </AccordionItem>
                  )}

                {auth.user.role === "marketer" && (
                  <AccordionItem border="none">
                    <NavLink
                      to="moderators"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiUser />
                          </span>
                          <span className="text">مودريتور</span>
                        </Flex>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}

                {auth.user.role === "marketer" && (
                  <AccordionItem border="none">
                    <NavLink
                      to="easyorder"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <Flex alignItems="center">
                          <span className="icon">
                            <FaLink />
                          </span>
                          <span className="text">الربط مع easyorder</span>
                        </Flex>
                        <Box
                          color="white"
                          bg="red.700"
                          paddingInline={2}
                          paddingBlock={1}
                          borderRadius="5px"
                        >
                          جديد
                        </Box>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}
                {(auth.user.role === "marketer" ||
                  auth.user.role === "merchant" ||
                  auth.user?.rule?.permissions?.includes(
                    "manage_requests"
                  )) && (
                  <AccordionItem border="none">
                    <NavLink
                      to="requests"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center">
                        <span className="icon">
                          <FiIcons.FiDollarSign />
                        </span>
                        <span className="text">
                          {t("layout.sidebar.requests")}
                        </span>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}
                {auth.user.role !== "moderator" && (
                  <AccordionItem border="none">
                    <NavLink
                      to="supports"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center">
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiMessageCircle />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.supports")}
                          </span>
                        </Flex>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}
                {auth.user.role !== "admin" && (
                  <AccordionItem border="none">
                    <NavLink
                      to="price_list"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center">
                        <span className="icon">
                          <FiIcons.FiDollarSign />
                        </span>
                        <span className="text">
                          {t("layout.sidebar.price_list")}
                        </span>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}
              </Accordion>
            </Box>
          </>
        )}
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
