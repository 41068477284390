const theme = {
  primary: "#539884",
  dark: "#273256",
  light: "#fff",
  text: "#8f9fbc",
  bg: "#F5F8FA",
  lightBg: "#25233f",
  error: "#ff3f3f",
  blue: "#2147CA",
  success: "#008000",
  border: "#eaeaea",
  secColor: "#b88403",
  gary1: "#a9a9a9",
  shadow: "0px 0px 30px 0px rgba(82, 63, 105, 0.05)",
};

export default theme;
