import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Initial state
const initialState = {
  isLoading: false,
  data: [],
  product: null,
  errors: [],
};

// Async thunk to get all products
export const getAllProducts = createAsyncThunk(
  "products/getAllProducts",
  async (_, thunkApi) => {
    try {
      const { data } = await axios.get("/api/get_all_products", {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

// Async thunk to update product order
export const updateProductsOrder = createAsyncThunk(
  "products/updateProductsOrder",
  async (updatedProducts, thunkApi) => {
    try {
      const { data } = await axios.patch(
        "/api/update_products_order",
        { products: updatedProducts },
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const updateProductsStock = createAsyncThunk(
  "products/updateProductsStock",
  async (updatedProducts, thunkApi) => {
    try {
      const { data } = await axios.patch(
        "/api/update_products_stock",
        updatedProducts,
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const productsOrderSlice = createSlice({
  name: "productsOrder",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle getAllProducts
      .addCase(getAllProducts.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.errors = [];
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.errors = action.payload.errors;
      })

      // Handle updateProductsOrder
      .addCase(updateProductsOrder.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateProductsOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.data = action.payload.data; // Assuming response contains updated data
        state.errors = [];
      })
      .addCase(updateProductsOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })
      // Handle updateProductsStock
      .addCase(updateProductsStock.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateProductsStock.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.data = action.payload.data; // Assuming response contains updated data
        state.errors = [];
      })
      .addCase(updateProductsStock.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default productsOrderSlice.reducer;
