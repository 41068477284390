import React from "react";
import {
  TbShoppingCartX,
  TbShoppingCartCheck,
  TbShoppingCartPause,
  TbShoppingCartOff,
  TbShoppingCartCode,
  TbShoppingCartCog,
  TbShoppingCartBolt,
  TbShoppingCartDollar,
  TbShoppingCartDown,
  TbShoppingCartCancel,
  TbShoppingCartQuestion,
  TbShoppingBagExclamation,
} from "react-icons/tb";
import { MdShoppingCartCheckout } from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";
import { Box, Flex, Text } from "@chakra-ui/react";
import theme from "../../global/theme";
import formatNumber from "../../../utilities/formatNumber";

export const orderStatuses = [
  {
    key: "allOrders",
    status: "",
    bgColor: "#539884",
    iconColor: "#1C3FAA",
    title: "اجمالي الطلبات",
    icon: <FiShoppingCart size={40} />,
  },
  {
    key: "pendingOrders",
    status: "pending",
    bgColor: "#539884",
    iconColor: "#1C3FAA",
    title: "طلبات معلقة",
    icon: <TbShoppingCartQuestion size={40} />,
  },
  {
    key: "preparingOrders",
    status: "preparing",
    bgColor: "#539884",
    iconColor: "#F78B00",
    title: "طلبات جار التحضير",
    icon: <TbShoppingCartBolt size={40} />,
  },
  {
    key: "shippedOrders",
    status: "shipped",
    bgColor: "#539884",
    iconColor: "#0094B7",
    title: "طلبات تم الشحن",
    icon: <MdShoppingCartCheckout size={40} />,
  },
  {
    key: "availableOrders",
    status: "available",
    bgColor: "#539884",
    iconColor: "#05DA73",
    title: "طلبات تم التوصيل",
    icon: <TbShoppingCartCheck size={40} />,
  },
  {
    key: "collectedOrders",
    status: "collected",
    bgColor: "#539884",
    iconColor: "#55c437",
    title: "طلبات تم التحصيل",
    icon: <TbShoppingCartDollar size={40} />,
  },
  {
    key: "skipOrders",
    status: "skip",
    bgColor: "#539884",
    iconColor: "#F68080",
    title: "جار الاسترجاع",
    icon: <TbShoppingCartCode size={40} />,
  },
  {
    key: "holdingOrders",
    status: "holding",
    bgColor: "#539884",
    iconColor: "#1f3FAA",
    title: "طلبات مؤجله",
    icon: <TbShoppingCartCog size={40} />,
  },
  {
    key: "asToReturnOrders",
    status: "ask_to_return",
    bgColor: "#539884",
    iconColor: "#aa0000",
    title: "طلب استرجاع من العميل",
    icon: <TbShoppingCartDown size={40} />,
  },
  {
    key: "returned1Orders",
    status: "returned1",
    bgColor: "#539884",
    iconColor: "#ff0000",
    title: "طلبات مرتجعة",
    icon: <TbShoppingCartX size={40} />,
  },
  {
    key: "returned2Orders",
    status: "returned2",
    bgColor: "#539884",
    iconColor: "#ff1111",
    title: "طلبات مرتجعة بعد التسليم",
    icon: <TbShoppingCartCancel size={40} />,
  },
  {
    key: "declined1Orders",
    status: "declined1",
    bgColor: "#539884",
    iconColor: "#500000",
    icon: <TbShoppingCartOff size={40} />,
    title: "طلبات ملغية",
  },
  {
    key: "declined2Orders",
    status: "declined2",
    bgColor: "#539884",
    iconColor: "#521C1B",
    title: "طلبات ملغية اثناء التحضير",
    icon: <TbShoppingCartPause size={40} />,
  },
  {
    key: "cancelledOrders",
    status: "cancelled",
    bgColor: "#539884",
    iconColor: "#521C1B",
    title: "طلبات معلومات غير صحيحة",
    icon: <TbShoppingBagExclamation size={40} />,
  },
];
const OrderStatusCard = ({
  status,
  icon,
  dataKey,
  bgColor,
  iconColor,
  title,
  filterLabel,
  orders,
  currentStatus,
  setCurrentStatus,
}) => {
  return (
    <Flex
      as={"div"}
      onClick={() => {
        setCurrentStatus(status);
      }}
      boxShadow={theme.shadow}
      bg={currentStatus === status ? bgColor : theme.light}
      padding={6}
      alignItems="center"
      justifyContent="space-between"
      cursor="pointer"
    >
      <Flex justifyContent="space-between">
        <Box color={iconColor}>{icon}</Box>
      </Flex>
      <Box>
        <Text
          fontWeight="bold"
          fontSize={{ base: 25, lg: 30 }}
          mt={4}
          color={theme.dark}
        >
          {formatNumber(orders.data?.[dataKey])}
        </Text>
        <Text
          fontWeight="bold"
          fontSize={{ base: 14, lg: 16 }}
          textTransform="capitalize"
          color={theme.dark}
        >
          {title}
        </Text>
        <Text
          fontWeight="bold"
          fontSize={{ base: 10, lg: 12 }}
          textTransform="capitalize"
          color={theme.dark}
          mt={3}
        >
          {filterLabel}
        </Text>
      </Box>
    </Flex>
  );
};

export default OrderStatusCard;
