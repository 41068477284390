import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Text,
  Alert,
  AlertIcon,
  Button,
  Spinner,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";

import InputSearch from "../../../shared/inputSearch/InputSearch";
import Pagination from "../../../shared/pagination/Pagination";
import Breadcrumbs from "../../../shared/breadcrumbs/Breadcrumbs";
import AvailableList from "./AvailableList";

import { getProducts } from "../../../../store/products/productsSlice";
import { getCategories } from "../../../../store/categories/categoriesSlice";

import { AvailableWrapper } from "./AvailableStyle";

import theme from "../../../global/theme";
import { useLocation, useSearchParams } from "react-router-dom";

const Available = ({ favorite = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get("page") || 1;
  const [query, setQuery] = useState("");
  const defaultFilter = { is_active: true };
  const [filter, setFilter] = useState(JSON.stringify(defaultFilter));
  const [filters, setFilters] = useState(JSON.stringify(defaultFilter));
  useEffect(() => {
    const currentFilter = JSON.parse(filter);
    delete currentFilter.category;
    currentFilter.my_fav = favorite;
    setFilter(JSON.stringify(currentFilter));
  }, [favorite]);
  useEffect(() => {
    const currentFilter = JSON.parse(filter);
    delete currentFilter.category;
    currentFilter.my_fav = pathname.includes("favorite_products");
    setFilter(JSON.stringify(currentFilter));
  }, [pathname]);
  useEffect(() => {
    dispatch(getProducts({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  useEffect(() => {
    dispatch(getCategories({ page: 1, size: 200, query: "" }));
  }, [dispatch]);

  return (
    <AvailableWrapper>
      <Breadcrumbs
        currentPage={
          favorite ? "المنتجات المفضلة" : t("pages.products.products")
        }
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {products.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {products.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}
      <SimpleGrid columns={{ base: 1, lg: 2 }}>
        <Flex alignItems="center" mb={8} flexWrap="wrap">
          <Button
            bg={!filters.category && !filters?.my_fav ? theme.primary : "none"}
            border={`1px solid ${
              !filters.category && !filters?.my_fav ? theme.primary : "#eee"
            }`}
            color={
              !filters.category && !filters?.my_fav ? theme.light : theme.dark
            }
            _hover={{ bg: theme.primary, color: theme.light }}
            me={3}
            marginBlock={3}
            disabled={favorite}
            onClick={() => {
              const currentFilter = JSON.parse(filter);
              delete currentFilter.category;
              currentFilter.my_fav = false;

              setFilters(currentFilter);
              setFilter(JSON.stringify(currentFilter));
            }}
          >
            {favorite ? "المنتجات المفضلة" : t("pages.products.products")}
          </Button>
          {/* {!favorite && (
          <Button
            bg={filters.my_fav ? theme.primary : "none"}
            border={`1px solid ${filters.my_fav ? theme.primary : "#eee"}`}
            color={filters.my_fav ? theme.light : theme.dark}
            _hover={{ bg: theme.primary, color: theme.light }}
            me={3}
            marginBlock={3}
            onClick={() => {
              const currentFilter = JSON.parse(filter);
              delete currentFilter.category;
              currentFilter.my_fav = true;
              setFilters(currentFilter);
              setFilter(JSON.stringify(currentFilter));
            }}
          >
            المفضلة
          </Button>
        )} */}
          {!favorite && categories.isLoading && <Spinner />}

          {!favorite && (
            <Select
              value={filters.category}
              width="200px"
              placeholder="كل الأقسام"
              onChange={(event) => {
                const selectedCategoryId = event.target.value;
                const currentFilter = JSON.parse(filter);
                if (selectedCategoryId) {
                  currentFilter.category = selectedCategoryId;
                  currentFilter.my_fav = false;
                  setFilters(currentFilter);
                  setFilter(JSON.stringify(currentFilter));
                } else {
                  const currentFilter = JSON.parse(filter);
                  delete currentFilter.category;
                  currentFilter.my_fav = false;

                  setFilters(currentFilter);
                  setFilter(JSON.stringify(currentFilter));
                }
              }}
              bg={theme.light}
              borderColor={theme.primary}
              _hover={{ borderColor: theme.primary }}
              color={theme.dark}
            >
              {categories?.data?.data?.map((cat) => (
                <option key={cat._id} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </Select>
          )}
        </Flex>
        <Flex mb={8} justifyContent="flex-end" justifySelf="flex-end">
          <InputSearch
            isLoading={products.isLoading}
            handleSearch={(query) => setQuery(query)}
          />
        </Flex>
      </SimpleGrid>
      {products.data?.itemsCount > 0 ? (
        <AvailableList data={products.data.data} page={page} />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.products.no_products")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={products.data?.itemsCount ?? 0}
          onChange={(page) => {
            searchParams.set("page", page);
            setSearchParams(searchParams);
          }}
        />
      </Flex>
    </AvailableWrapper>
  );
};

export default Available;
