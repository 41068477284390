import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Text,
  Alert,
  AlertIcon,
  Box,
  Button,
  Spinner,
} from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import NotificationsList from "./NotificationsList";

import {
  getNotifications,
  updateNotifications,
} from "../../../store/notifications/notificationsSlice";

import { NotificationsWrapper } from "./NotificationsStyle";

import theme from "../../global/theme";

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const auth = useSelector((state) => state.auth);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(""); // Initialize with empty string or default query
  const [type, setType] = useState(""); // Using an object to store filter type

  useEffect(() => {
    if (type) {
      dispatch(
        getNotifications({ page, query, filter: JSON.stringify({ type }) })
      );
    } else {
      dispatch(getNotifications({ page, query, filter: JSON.stringify({}) }));
    }
  }, [dispatch, page, query, type]);

  const handleFilterChange = (type) => {
    setType(type); // Set the filter object with the selected type
    setPage(1); // Reset to the first page when filter changes
  };

  const handleMarkAllAsRead = async () => {
    try {
      await dispatch(updateNotifications()).unwrap();
      // You may want to update the notifications list here instead of reloading the page
      dispatch(
        getNotifications({
          page,
          query,
          filter: JSON.stringify(type ? { type } : {}),
        })
      );
    } catch (error) {
      console.error("Failed to update notifications:", error);
    }
  };

  return (
    <NotificationsWrapper>
      <Breadcrumbs
        currentPage={t("pages.notifications.notifications")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {notifications.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {notifications.errors.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <Flex mb={4} flexWrap="wrap">
        <Button
          marginInline={2}
          mb={4}
          p={3}
          borderRadius={6}
          bg={type === "" ? theme.primary : theme.border}
          color={type === "" ? theme.light : theme.dark}
          onClick={() => handleFilterChange("")}
        >
          جميع الاشعارات
        </Button>
        <Button
          marginInline={2}
          mb={4}
          p={3}
          borderRadius={6}
          bg={type === "order_note" ? theme.primary : theme.border}
          color={type === "order_note" ? theme.light : theme.dark}
          onClick={() => handleFilterChange("order_note")}
        >
          ملاحظات الطلب
        </Button>
        <Button
          marginInline={2}
          mb={4}
          p={3}
          borderRadius={6}
          bg={type === "request" ? theme.primary : theme.border}
          color={type === "request" ? theme.light : theme.dark}
          onClick={() => handleFilterChange("request")}
        >
          طلبات السحب
        </Button>
        <Button
          marginInline={2}
          mb={4}
          p={3}
          borderRadius={6}
          bg={type === "order_status" ? theme.primary : theme.border}
          color={type === "order_status" ? theme.light : theme.dark}
          onClick={() => handleFilterChange("order_status")}
        >
          تغيير حالة الطلب
        </Button>
        {auth.user.role === "admin" && (
          <Button
            marginInline={2}
            mb={4}
            p={3}
            borderRadius={6}
            bg={type === "ticket" ? theme.primary : theme.border}
            color={type === "ticket" ? theme.light : theme.dark}
            onClick={() => handleFilterChange("ticket")}
          >
            تغيير المخزون
          </Button>
        )}
      </Flex>

      <Box mb={4}>
        <Button
          type="button"
          bg="red.700"
          color="white"
          _hover={{ bg: "red.500" }}
          onClick={handleMarkAllAsRead}
        >
          تمييز الكل كمقروء
        </Button>
      </Box>

      {notifications.isLoading ? (
        <Flex
          textAlign="center"
          bg={theme.light}
          height={200}
          alignItems="center"
          boxShadow={theme.shadow}
          justifyContent="center"
          borderRadius={16}
        >
          <Flex padding={6} alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        </Flex>
      ) : notifications.data?.itemsCount > 0 ? (
        <NotificationsList data={notifications.data.data} />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          height={200}
          alignItems="center"
          boxShadow={theme.shadow}
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.notifications.no_notifications")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={notifications.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>
    </NotificationsWrapper>
  );
};

export default Notifications;
